import { AnalyticService, CohortsRequest, QueryCohortsResponse, CohortsRequestSort } from 'gen/service/analytic'
import { CohortsFilter, CohortsQuery, JoinOperator, SelectorExpr, TimeRangeLite } from 'gen/service/common'
import { pickBy, identity, isUndefined } from 'lodash'
import { useInfinite } from './use-infinite'
import { getAccessToken } from './use-access-token'

const PAGE_SIZE = 50

export const useQueryCohorts = (
  projectOwner?: string,
  projectSlug?: string,
  query?: CohortsQuery,
  timeRange?: TimeRangeLite,
  search?: string,
  sorting?: CohortsRequestSort
) => {
  const getKey = (pageIndex: number, previousPageData: QueryCohortsResponse | undefined) => {
    if (!projectOwner || !projectSlug) return null

    // reached the end
    if (previousPageData && previousPageData?.result?.values && previousPageData.result.values.length < PAGE_SIZE)
      return null

    const req = pickBy(
      {
        projectOwner,
        projectSlug,
        query,
        timeRange,
        limit: PAGE_SIZE,
        offset: pageIndex * PAGE_SIZE,
        searchQuery: search,
        sorting,
      },
      identity
    ) as CohortsRequest

    return req
  }

  const fetcher = async (req) => {
    const accessToken = await getAccessToken()
    return await AnalyticService.QueryCohorts(req, {
      headers: {
        'content-type': 'application/json',
        authorization: accessToken == 'anonymous' ? undefined : `Bearer ${accessToken}`,
      } as any,
    })
  }

  // const { data, error, mutate } = useApi(AnalyticService.QueryCohorts, req)
  const { data, error, isLoadingMore, mutate, isReachingEnd, fetchNextPage, isRefreshing } = useInfinite(
    getKey,
    fetcher,
    (d) => d.result?.values || [],
    PAGE_SIZE,
    {
      revalidateFirstPage: false,
      revalidateOnFocus: false,
    }
  )

  return {
    data,
    error,
    mutate,
    isLoading: !data && !error,
    isLoadingMore,
    fetchNextPage,
    hasMore: !isReachingEnd,
  }
}

export const genCohortQuery = (eventName?: string, timeRange?: TimeRangeLite, selectorExpr?: SelectorExpr): CohortsQuery => {
  const data = {
    name: eventName,
    aggregation: {
      total: {},
      operator: "GTE",
      value: [
          {
              doubleValue: "0"
          }
      ]
    } as any,
    selectorExpr: selectorExpr,
    symbol: true,
    timeRange,
    joinOperator: JoinOperator.AND
  }
  const filter: CohortsFilter = pickBy(data, item => !isUndefined(item))
  return {
    groups: [{
      filters: [filter],
      joinOperator: JoinOperator.AND
    }],
    joinOperator: JoinOperator.AND
  }
}

/**
 * This function is used to get all accounts from a cohort, for download csv.
 * @param totalCount 
 * @param projectOwner 
 * @param projectSlug 
 * @param query 
 * @param timeRange 
 * @param search 
 * @param sorting 
 * @returns 
 */
export const getAllAccounts = async (
  totalCount: number,
  projectOwner: string,
  projectSlug: string,
  query?: CohortsQuery,
  timeRange?: TimeRangeLite,
  search?: string,
  sorting?: CohortsRequestSort,
) => {
  const req = pickBy(
    {
      projectOwner,
      projectSlug,
      query,
      timeRange,
      limit: totalCount,
      offset: 0,
      searchQuery: search,
      sorting,
    },
    identity
  ) as CohortsRequest

  const accessToken = await getAccessToken()
  return await AnalyticService.QueryCohorts(req, {
    headers: {
      'content-type': 'application/json',
      authorization: accessToken == 'anonymous' ? undefined : `Bearer ${accessToken}`,
    } as any,
  })
}