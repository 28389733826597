/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"
import * as CommonCommon from "../../common/protos/common.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);
export type GetMetricsRequest = {
  projectId?: string
  name?: string
  version?: number
}

export type GetMetricsResponse = {
  metrics?: MetricInfo[]
}

export type MetricInfoLabelValues = {
  values?: string[]
}

export type MetricInfo = {
  name?: string
  displayName?: string
  projectId?: string
  contractName?: string[]
  contractAddress?: string[]
  chainId?: string[]
  labels?: {[key: string]: MetricInfoLabelValues}
  metadata?: MetricMetadata
}

export type MetricMetadata = {
  type?: string
  unit?: string
  help?: string
  lastSeen?: string
}

export type QueryRangeRequest = {
  projectOwner?: string
  projectSlug?: string
  queries?: CommonCommon.Query[]
  formulas?: CommonCommon.Formula[]
  samplesLimit?: number
  timeRange?: CommonCommon.TimeRangeLite
  projectId?: string
  version?: number
  samplesOffset?: number
}

export type QueryValueRequest = {
  projectOwner?: string
  projectSlug?: string
  queries?: CommonCommon.Query[]
  formulas?: CommonCommon.Formula[]
  time?: string
  samplesLimit?: number
  version?: number
  timezone?: string
  samplesOffset?: number
}

export type QueryValueResponseResult = {
  sample?: MetricsQueryResponseSample[]
  error?: string
  alias?: string
  id?: string
}

export type QueryValueResponse = {
  results?: QueryValueResponseResult[]
}

export type MetricsQueryResponseMatrix = {
  samples?: MetricsQueryResponseSample[]
  totalSamples?: number
}

export type MetricsQueryResponseSample = {
  metric?: MetricsQueryResponseMetric
  values?: MetricsQueryResponseValue[]
}

export type MetricsQueryResponseMetric = {
  name?: string
  labels?: {[key: string]: string}
  displayName?: string
}

export type MetricsQueryResponseValue = {
  timestamp?: string
  value?: number
}


type BaseMetricsQueryResponseResult = {
  alias?: string
  id?: string
  computeStats?: CommonCommon.ComputeStats
}

export type MetricsQueryResponseResult = BaseMetricsQueryResponseResult
  & OneOf<{ matrix: MetricsQueryResponseMatrix; error: string }>

export type MetricsQueryResponse = {
  results?: MetricsQueryResponseResult[]
}

export type Series = {
  name?: string
  projectId?: string
  contract?: string
  displayName?: string
  contractAddress?: string
  labels?: {[key: string]: string}
}

export class ObservabilityService {
  static GetMetrics(req: GetMetricsRequest, initReq?: fm.InitReq): Promise<GetMetricsResponse> {
    return fm.fetchReq<GetMetricsRequest, GetMetricsResponse>(`/api/v1/metrics?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static QueryRange(req: QueryRangeRequest, initReq?: fm.InitReq): Promise<MetricsQueryResponse> {
    return fm.fetchReq<QueryRangeRequest, MetricsQueryResponse>(`/api/v1/metrics/${req["projectOwner"]}/${req["projectSlug"]}/query_range`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static Query(req: QueryValueRequest, initReq?: fm.InitReq): Promise<QueryValueResponse> {
    return fm.fetchReq<QueryValueRequest, QueryValueResponse>(`/api/v1/metrics/${req["projectOwner"]}/${req["projectSlug"]}/query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}