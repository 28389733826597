import { ChartConfigCalculation, ChartConfigTableConfig } from 'gen/service/web'
import { produce } from 'immer'
import { defaults } from 'lodash'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import { useMemo } from 'react'
import { TableColumnsFlight, useFlight } from 'lib/data/use-flight'
import classNames from 'lib/classnames'
import { MetricsQueryResponse } from 'gen/service/observability'
import { ValueOptions } from './ValueOptions'
import { defaultConfig as defaultValueConfig } from './ValueControls'
import { getColumnNameId } from 'lib/metrics/table'
import { useTableLimit } from '../../../lib/data/use-table-state'
import { ChartChartType, ChartConfigValueFormatter } from '../../../gen/service/web'
import { SyncExecuteSQLResponse } from '../../../gen/service/analytic'
import { TabularDataColumnType } from '../../../gen/service/common'

interface Props {
  config?: ChartConfigTableConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigTableConfig) => void
  data?: MetricsQueryResponse | SyncExecuteSQLResponse
}

export const defaultConfig: ChartConfigTableConfig = {
  calculation: ChartConfigCalculation.LAST,
  sortColumns: [],
  showColumns: undefined,
  columnWidths: {},
  columnOrders: [],
  showPlainData: false,
  calculations: {},
  valueConfigs: {},
  rowLimit: 200
}

export function getDefaultValueConfig(type?: TabularDataColumnType) {
  switch (type) {
    case TabularDataColumnType.NUMBER:
      return {
        ...defaultValueConfig,
        valueFormatter: ChartConfigValueFormatter.NumberFormatter
      }
    case TabularDataColumnType.TIME:
      return {
        ...defaultValueConfig,
        valueFormatter: ChartConfigValueFormatter.DateFormatter
      }
    default:
      return {
        ...defaultValueConfig,
        valueFormatter: ChartConfigValueFormatter.StringFormatter
      }
  }
}

const CalculationItems = [
  { label: 'All', value: ChartConfigCalculation.ALL },
  { label: 'Last', value: ChartConfigCalculation.LAST },
  { label: 'First', value: ChartConfigCalculation.FIRST },
  { label: 'Total', value: ChartConfigCalculation.TOTAL },
  { label: 'Mean', value: ChartConfigCalculation.MEAN },
  { label: 'Max', value: ChartConfigCalculation.MAX },
  { label: 'Min', value: ChartConfigCalculation.MIN }
]

export function TableControls({ config, defaultOpen, onChange, data }: Props) {
  config = defaults(config, defaultConfig)

  function onCalculationChange(col: string, cal) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.calculations = draft.calculations || {}
          draft.calculations[col] = cal
        })
      )
  }

  function onValueConfigChange(col: string, valueConfig) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.valueConfigs = draft.valueConfigs || {}
          draft.valueConfigs[col] = valueConfig
        })
      )
  }

  function onMapSeriesAsColumnsChange(e) {
    config && onChange(produce(config, (draft) => void (draft.showPlainData = e.target.checked)))
  }

  function onRowLimitChange(e) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft.rowLimit = parseInt(e.target.value)
        })
      )
  }

  const calculations = useMemo(() => {
    if (!config?.showPlainData) {
      return CalculationItems.filter((item) => item.value !== ChartConfigCalculation.ALL)
    }
    return CalculationItems
  }, [config?.showPlainData])

  const { isFlightEnabled } = useFlight()

  const isSql = data?.['result'] !== undefined

  const columns = useMemo(() => {
    if (config?.showPlainData) {
      return []
    }
    const map: { [k: string]: { name: string; type?: TabularDataColumnType } } = {}

    if (isSql) {
      const results = (data as SyncExecuteSQLResponse)?.result
      if (results) {
        for (const [name, type] of Object.entries(results?.columnTypes || {})) {
          map[name] = {
            name,
            type
          }
        }
      }
    } else {
      const results = (data as MetricsQueryResponse)?.results
      for (const r of results || []) {
        for (const s of r?.matrix?.samples || []) {
          const { columnId, columnName } = getColumnNameId(s?.metric?.labels || {}, r.alias, s.metric?.displayName)
          map[columnId] = {
            name: columnName
          }
        }
      }
    }
    return Object.keys(map)
      .sort()
      .map((k) => ({ columnId: k, column: map[k] }))
  }, [data, config])

  const limit = useTableLimit(ChartChartType.TABLE, config)

  return (
    <DisclosurePanel
      title="Table Options"
      defaultOpen={defaultOpen}
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
    >
      {!isSql && (
        <div className="mt-1 flex gap-4">
          <div className="flex h-8">
            <span className="ml-2 inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500 sm:text-sm">
              Row Limit
            </span>
            <input
              type="number"
              max={1000}
              min={1}
              className="border-border-color mr-1 w-32 rounded-r-md border-l-0"
              defaultValue={100}
              value={config?.rowLimit || limit}
              onChange={onRowLimitChange}
            />
          </div>
          <div
            className={classNames(
              isFlightEnabled(TableColumnsFlight) ? '' : 'hidden',
              'text-text-foreground mx-2 inline-flex items-center sm:text-sm'
            )}
          >
            <input
              type="checkbox"
              className="border-border-color mr-1 rounded"
              checked={config?.showPlainData}
              onChange={onMapSeriesAsColumnsChange}
            />
            Show plain data
          </div>
          {config?.showPlainData && (
            <>
              <span className="inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500 sm:text-sm">
                Calculation
              </span>
              <select
                value={config.calculation}
                className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300  pl-4  pr-7  text-gray-500 sm:text-sm"
                onChange={(e) => onCalculationChange('', e.target.value as ChartConfigCalculation)}
              >
                {calculations.map((d) => {
                  return (
                    <option key={d.value} value={d.value}>
                      {d.label}
                    </option>
                  )
                })}
              </select>
            </>
          )}
          <div></div>
        </div>
      )}

      <div className="flex flex-col gap-2 divide-y divide-gray-300">
        {columns.map(({ columnId, column }) => (
          <div className="flex items-center" key={columnId}>
            <h4 className="mt-2 w-48 px-2 text-sm text-gray-800">{column.name}</h4>
            <div className="mt-2  flex flex-1 flex-wrap items-center rounded-md">
              {!isSql && (
                <div className="flex">
                  <span className="ml-2 inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500 sm:text-sm">
                    Calculation
                  </span>
                  <select
                    value={(config?.calculations && config?.calculations[columnId]) || ChartConfigCalculation.LAST}
                    className="inline-flex h-full items-center rounded-r-md border border-l-0 border-gray-300  py-1 pl-4  pr-7  text-gray-500 sm:text-sm"
                    onChange={(e) => onCalculationChange(columnId, e.target.value as ChartConfigCalculation)}
                  >
                    {calculations.map((d) => {
                      return (
                        <option key={d.value} value={d.value}>
                          {d.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              )}
              <div className="ml-2 flex-1">
                <ValueOptions
                  onChange={(cfg) => onValueConfigChange(columnId, cfg)}
                  config={(config?.valueConfigs && config.valueConfigs[columnId]) || getDefaultValueConfig(column.type)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </DisclosurePanel>
  )
}
