import { MetricsQueryResponseResult, MetricsQueryResponseMetric } from '@sentio/service/observability'
import { ChartChartType, ChartConfigCalculation } from '@sentio/service/web'
import { Series } from './series'
import { useGlobalWorker } from 'lib/worker/use-global-worker'
import { useCallback } from 'react'

export const useAsyncComputeSeries = () => {
  const { postMessageAsync } = useGlobalWorker()

  return useCallback(
    async (
      results: MetricsQueryResponseResult[],
      chartType?: ChartChartType,
      calculation: ChartConfigCalculation = ChartConfigCalculation.ALL,
      showSymbol = false,
      idSuffix?: string,
      tz?: string
    ): Promise<{
      series: Series<Date>[]
      legend: string[]
      seriesToMetricLabels: { name: string; labels: MetricsQueryResponseMetric['labels'] }[]
    }> => {
      return postMessageAsync('ComputeSeries', {
        results,
        chartType,
        calculation,
        showSymbol,
        idSuffix,
        tz
      })
    },
    []
  )
}
