import { ChartConfigDirection, ChartConfigCalculation } from 'gen/service/web'
import { produce } from 'immer'
import { ChartConfigPieConfig, ChartConfigPieConfigPieType } from 'gen/service/web'
import { defaults } from 'lodash'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import ButtonGroup from '../../common/buttons/NewButtonGroup'

interface Props {
  config?: ChartConfigPieConfig
  defaultOpen?: boolean
  onChange: (config: ChartConfigPieConfig) => void
}

export const defaultConfig: ChartConfigPieConfig = {
  pieType: ChartConfigPieConfigPieType.Pie,
  calculation: ChartConfigCalculation.LAST,
  showPercent: true,
  showValue: true
}

const CalculationItems = [
  { label: 'Last', value: ChartConfigCalculation.LAST },
  { label: 'First', value: ChartConfigCalculation.FIRST },
  { label: 'Total', value: ChartConfigCalculation.TOTAL },
  { label: 'Mean', value: ChartConfigCalculation.MEAN },
  { label: 'Max', value: ChartConfigCalculation.MAX },
  { label: 'Min', value: ChartConfigCalculation.MIN }
]

const PieTypeItems = [
  { label: 'Pie', value: ChartConfigPieConfigPieType.Pie },
  { label: 'Donut', value: ChartConfigPieConfigPieType.Donut }
]

export function PieChartControls({ config, defaultOpen, onChange }: Props) {
  config = defaults(config, defaultConfig)

  function onCalculationChange(cal) {
    config && onChange(produce(config, (draft) => void (draft.calculation = cal)))
  }

  function onPieTypeChange(pieType) {
    config && onChange(produce(config, (draft) => void (draft.pieType = pieType)))
  }

  function toggle(field: string, value: boolean) {
    config &&
      onChange(
        produce(config, (draft) => {
          draft[field] = value
        })
      )
  }

  return (
    <DisclosurePanel
      title="Pie Chart Options"
      defaultOpen={defaultOpen}
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
    >
      <div className="flex items-center gap-4 p-2">
        <div className="mt-1 flex rounded-md shadow-sm">
          <ButtonGroup buttons={PieTypeItems} value={config.pieType} onChange={onPieTypeChange} />
        </div>
        <div className="mt-1 flex rounded-md shadow-sm">
          <span className="sm:text-ilabel inline-flex items-center rounded-l-md border  border-gray-300 bg-gray-50 px-3 text-gray-500">
            Calculation
          </span>
          <select
            value={config.calculation}
            className="sm:text-ilabel text-text-foreground inline-flex items-center rounded-r-md border border-l-0  border-gray-300   pl-4 pr-7"
            onChange={(e) => onCalculationChange(e.target.value as ChartConfigDirection)}
          >
            {CalculationItems.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
        </div>
        <div className="sm:text-ilabel text-text-foreground inline-flex items-center">
          <input
            type="checkbox"
            className="border-border-color mr-1 rounded"
            checked={config?.showValue}
            onChange={(e) => toggle('showValue', e.target.checked)}
          />
          Show value
        </div>
        <div className="sm:text-ilabel text-text-foreground inline-flex items-center">
          <input
            type="checkbox"
            className="border-border-color mr-1 rounded"
            checked={config?.showPercent}
            onChange={(e) => toggle('showPercent', e.target.checked)}
          />
          Show percent
        </div>
      </div>
    </DisclosurePanel>
  )
}
