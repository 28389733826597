import {
  AccessorFnColumnDef,
  AccessorKeyColumnDef,
  ColumnDef,
  ColumnOrderState,
  SortingState
} from '@tanstack/react-table'
import '@glideapps/glide-data-grid/dist/index.css'
import {
  CompactSelection,
  DataEditor,
  EditableGridCell,
  GridCell,
  GridCellKind,
  GridColumn,
  GridSelection,
  Item,
  Rectangle,
  SpriteMap,
  Theme
} from '@glideapps/glide-data-grid'
import { useCallback, useMemo, useState } from 'react'
import { useLayer } from 'react-laag'
import Menu from '../../menu/Menu'
import { EyeIcon, EyeSlashIcon, BarsArrowUpIcon, BarsArrowDownIcon } from '@heroicons/react/24/outline'
import { TbArrowAutofitWidth } from 'react-icons/tb'
import { Column } from '../../../lib/metrics/table'
import SpinLoading from '../util/SpinLoading'
import { useDarkMode } from 'lib/util/use-dark-mode'

const LIGHT_THEME: Partial<Theme> = {
  bgHeader: 'white',
  bgHeaderHasFocus: 'white'
}

const DARK_THEME: Partial<Theme> = {
  bgHeader: '#202020',
  bgHeaderHasFocus: '#202020',
  bgHeaderHovered: '#5d6165',
  bgCell: '#202020',
  textDark: '#e4e4e4',
  textMedium: '#b8b8b8',
  textLight: '#a6a6a6',
  textBubble: '#a6a6a6',
  textHeader: '#a1a1a1',
  borderColor: 'rgba(255, 255, 255, 0.2)',
  horizontalBorderColor: 'rgba(255, 255, 255, 0.2)',
  drilldownBorder: 'rgba(255, 255, 255, 0.2)'
}

interface Props {
  columns: ColumnDef<any>[]
  data: Array<any>
  allowEditColumn?: boolean
  allowResizeColumn?: boolean
  showColumns?: { [key: string]: boolean }
  onShowColumnChange?: (column: string, show: boolean) => void
  onSortChange?: (sortBy: SortingState) => void
  allowSort?: boolean
  sortings?: SortingState
  onResizeColumn?: (columnSizes: { [col: string]: number | null }) => void
  columnOrders?: string[]
  onColumnOrderChanged?: (order: ColumnOrderState) => void
  isFetching?: boolean
}

export default function DataGrid({
  columns,
  data,
  allowResizeColumn,
  allowEditColumn,
  allowSort,
  sortings,
  onSortChange,
  showColumns,
  onResizeColumn,
  onShowColumnChange,
  columnOrders,
  onColumnOrderChanged,
  isFetching
}: Props) {
  const [columnSize, setColumnSize] = useState<{ [col: string]: number | null }>({})
  const isColumnSizeControlled = allowResizeColumn && onResizeColumn != null
  const isDarkMode = useDarkMode()

  const cols: GridColumn[] = useMemo(() => {
    if (columnOrders && columnOrders?.length > 0) {
      columns = columns.sort((a, b) => {
        const aIndex = columnOrders.indexOf(a.id as string)
        const bIndex = columnOrders.indexOf(b.id as string)
        return aIndex - bIndex
      })
    }
    const result: GridColumn[] = []
    columns.forEach((col, idx) => {
      let title = col.header as string
      if (allowSort) {
        const sort = sortings?.find((s) => s.id === col.id)
        if (sort) {
          title += sort.desc ? ' ↓︎' : ' ↑︎'
        }
      }
      const hideColumn = showColumns?.[col.id!] == false
      if (!allowEditColumn && hideColumn) {
        return
      }
      let width: number | undefined = undefined
      if (isColumnSizeControlled) {
        width = col.size && col.size > 0 ? col.size : undefined
      } else if (col.id) {
        const size = columnSize[col.id]
        width = size && size > 0 ? size : undefined
      }

      result.push({
        id: col.id as string,
        title,
        width,
        hasMenu: allowEditColumn || allowSort || allowResizeColumn,
        menuIcon: 'dots',
        icon: hideColumn ? 'invisible' : undefined,
        grow: allowResizeColumn ? undefined : idx == columns.length - 1 ? 1 : 0
      } as GridColumn)
    })
    return result
  }, [columns, showColumns, allowSort, sortings, columnOrders, allowResizeColumn, isColumnSizeControlled, columnSize])

  const onColumnResize = useCallback(
    (id: string, newSize: number) => {
      if (!isColumnSizeControlled) {
        setColumnSize((cv) => {
          return {
            ...cv,
            [id]: newSize
          }
        })
      }
      const columnSizes = {}
      for (const col of columns) {
        if (col.size && col.id) {
          columnSizes[col.id] = col.size
        }
      }

      columnSizes[id] = newSize
      onResizeColumn?.(columnSizes)
    },
    [columns, onResizeColumn, isColumnSizeControlled]
  )

  const [showSearch, setShowSearch] = useState(false)

  const onKeydown = useCallback((event) => {
    if ((event.ctrlKey || event.metaKey) && event.code === 'KeyF') {
      setShowSearch((cv) => !cv)
      event.stopPropagation()
      event.preventDefault()
    }
  }, [])
  const [searchValue, setSearchValue] = useState('')

  const [menu, setMenu] = useState<{
    col: number
    bounds: Rectangle
  }>()
  const isOpen = menu !== undefined
  const { layerProps, renderLayer } = useLayer({
    isOpen,
    auto: true,
    placement: 'bottom-end',
    triggerOffset: 0,
    onOutsideClick: () => setMenu(undefined),
    trigger: {
      getBounds: () => ({
        left: menu?.bounds.x ?? 0,
        top: menu?.bounds.y ?? 0,
        width: menu?.bounds.width ?? 0,
        height: menu?.bounds.height ?? 0,
        right: (menu?.bounds.x ?? 0) + (menu?.bounds.width ?? 0),
        bottom: (menu?.bounds.y ?? 0) + (menu?.bounds.height ?? 0)
      })
    }
  })
  const onHeaderMenuClick = useCallback((col: number, bounds: Rectangle) => {
    setMenu({
      col,
      bounds
    })
  }, [])

  const menuItems = useMemo(() => {
    const menus: IMenuItem[] = []
    if (menu) {
      const col = cols[menu.col]
      if (allowEditColumn) {
        if (showColumns?.[col.id!] == false) {
          menus.push({
            key: 'show',
            label: 'Show Column',
            icon: <EyeIcon className="mr-1 h-4 w-4" />,
            data: col
          })
        } else {
          menus.push({
            key: 'hide',
            label: 'Hide Column',
            icon: <EyeSlashIcon className="mr-1 h-4 w-4" />,
            data: col
          })
        }
      }
      if (allowSort && col.id !== '__index') {
        menus.push({
          key: 'asc',
          label: 'Sort Ascending',
          icon: <BarsArrowUpIcon className="mr-1 h-4 w-4" />,
          data: col
        })
        menus.push({
          key: 'desc',
          label: 'Sort Descending',
          icon: <BarsArrowDownIcon className="mr-1 h-4 w-4" />,
          data: col
        })
      }
      if (allowResizeColumn) {
        menus.push({
          key: 'auto_width',
          label: 'Auto width',
          data: col,
          icon: <TbArrowAutofitWidth className="mr-1 h-4 w-4" />
        })
      }
    }
    return menus
  }, [menu, cols, allowEditColumn, showColumns, allowSort, sortings, allowResizeColumn])

  const onSelectMenu = useCallback(
    (menuKey: string, _, data) => {
      const column = data?.data as ColumnDef<any>
      switch (menuKey) {
        case 'hide':
          onShowColumnChange?.(column.id as string, false)
          break
        case 'show':
          onShowColumnChange?.(column.id as string, true)
          break
        case 'asc':
          onSortChange?.([{ id: column.id as string, desc: false }])
          break
        case 'desc':
          onSortChange?.([{ id: column.id as string, desc: true }])
          break
        case 'auto_width': {
          column.id && onColumnResize(column.id, -1)
          break
        }
      }
      setMenu(undefined)
    },
    [onResizeColumn, onShowColumnChange, onSortChange, columns]
  )

  const onHeaderClicked = useCallback(
    (col: number, event) => {
      if (allowSort && onSortChange) {
        const column = cols[col]
        if (column.id == '__index') {
          return
        }
        const sorting = sortings || []
        if (column.id) {
          const sortIndex = sorting.findIndex((s) => s.id === column.id)
          if (sortings && sortIndex != -1) {
            const sort = sorting[sortIndex]
            if (sort?.desc == true) {
              onSortChange(sorting.filter((c) => c.id !== column.id))
            } else {
              onSortChange([{ id: column.id, desc: true }])
            }
          } else {
            onSortChange([{ id: column.id, desc: false }])
          }
        }
      }
    },
    [allowSort, onSortChange, sortings]
  )

  const onColMoved = useCallback(
    (sourceIndex: number, targetIndex: number) => {
      if (onColumnOrderChanged) {
        const currentOrders = columnOrders ?? columns.map((c) => c.id as string)
        const source = currentOrders[sourceIndex]
        const target = currentOrders[targetIndex]
        if (source && target) {
          const newOrders = [...currentOrders]
          newOrders[sourceIndex] = target
          newOrders[targetIndex] = source
          onColumnOrderChanged(newOrders)
        }
      }
    },
    [columnOrders, columns, onColumnOrderChanged]
  )

  const filterData = useMemo(() => {
    if (searchValue) {
      const filter = searchValue.toLowerCase()
      return data.filter((row, idx) => {
        for (const column of columns) {
          if (column['accessorFn']) {
            const col = column as AccessorFnColumnDef<any>
            const display = col.accessorFn(row, 0)
            if (String(display).toLowerCase().includes(filter)) {
              return true
            }
          }
          let value: any
          if (column.id === '__index') {
            value = idx + 1
          } else {
            value = row[column.id as string]
          }
          if (value && value.toString().toLowerCase().includes(filter)) {
            return true
          }
        }
        return false
      })
    }
    if (sortings && sortings.length > 0) {
      const sort = sortings[0]
      return data.sort((a, b) => {
        const aValue = a[sort.id]
        const bValue = b[sort.id]
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          if (sort.desc) {
            return bValue - aValue
          } else {
            return aValue - bValue
          }
        }
        if (sort.desc) {
          return String(aValue).localeCompare(bValue)
        } else {
          return String(bValue).localeCompare(aValue)
        }
      })
    }
    return data
  }, [data, columns, searchValue])

  const getData = useCallback(
    ([col, row]: Item): GridCell => {
      const gridCol = cols[col]
      const column = columns.find((c) => c.id == gridCol.id)
      const rowData = filterData[row]
      let displayData = ''
      if (column?.['accessorFn']) {
        const accessorFnCol = column as AccessorFnColumnDef<any>
        displayData = String(accessorFnCol.accessorFn(rowData, row))
      } else if (column?.['cell']) {
        const col = column as Column
        const info = {
          getValue() {
            return rowData[col.id]
          }
        }
        displayData = String(col.cell(info))
      }
      let kind = GridCellKind.Text
      let value: any
      if (column?.id === '__index') {
        kind = GridCellKind.RowID
        value = `${row + 1}`
      } else {
        value = rowData[column?.id as string]
      }
      if (typeof value === 'number') {
        kind = GridCellKind.Number
      }
      return {
        kind,
        data: value ?? '',
        displayData: displayData,
        allowOverlay: true,
        readonly: true
      }
    },
    [filterData, cols, columns]
  )

  return (
    <div className="h-full w-full" onKeyDown={onKeydown}>
      <SpinLoading className="h-full min-h-[200px] w-full" loading={isFetching} showMask>
        {cols.length > 0 && (
          <DataEditor
            getCellContent={getData}
            onKeyDown={onKeydown}
            onColumnResize={(col, newSize) => col.id && onColumnResize(col.id, newSize)}
            columns={cols}
            rows={filterData.length}
            getCellsForSelection={true}
            minColumnWidth={50}
            maxColumnAutoWidth={750}
            maxColumnWidth={2000}
            searchResults={[]}
            searchValue={searchValue}
            onSearchValueChange={setSearchValue}
            showSearch={showSearch}
            onHeaderMenuClick={onHeaderMenuClick}
            onSearchClose={() => {
              setShowSearch(false)
              setSearchValue('')
            }}
            // gridSelection={selection}
            // onGridSelectionChange={setSelection}
            headerIcons={HEADER_ICONS}
            onColumnMoved={onColMoved}
            onColumnProposeMove={(sourceIndex, targetIndex) => {
              return onColumnOrderChanged != null && sourceIndex != 0 && targetIndex != 0
            }}
            width={'100%'}
            height={'100%'}
            onHeaderClicked={onHeaderClicked}
            smoothScrollX
            rowHeight={32}
            theme={isDarkMode ? DARK_THEME : LIGHT_THEME}
          />
        )}
        {isOpen &&
          renderLayer(
            <div className="z-20" {...layerProps}>
              <Menu items={menuItems} onSelectMenu={onSelectMenu} />
            </div>
          )}
      </SpinLoading>
    </div>
  )
}

const HEADER_ICONS: SpriteMap = {
  visible: (
    p
  ) => `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
  <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
</svg>
`,
  invisible: (
    p
  ) => `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88" />
</svg>
`
}
