import { ColumnDef } from '@tanstack/react-table'
import { HexNumber } from 'components/common/tags/HexNumber'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { EventLogColumn } from 'gen/service/common'
import { CHAIN_MAP } from '@sentio/chain'
import classNames from 'lib/classnames'
import { memo } from 'react'
import { LinkifyText } from 'components/common/text/LinkifyText'
import useTimeRange from '../../lib/data/use-timerange'

dayjs.extend(utc)
dayjs.extend(timezone)

// facets path of builtin columns

export const enum DefaultShowFacets {
  Severity = 'severity',
  ChainId = 'chain',
  Timestamp = 'timestamp',
  ContractName = 'contract',
  EventName = 'event_name',
  ContractAddress = 'address',
  Message = 'message',
  BlockNumber = 'block_number',
  TransactionHash = 'transaction_hash',
  DistinctId = 'distinct_id',
  LogIndex = 'log_index',
  TransactionIndex = 'transaction_index'
}

export const ObsoleteFacets = {
  'chain.id': DefaultShowFacets.ChainId,
  '@timestamp': DefaultShowFacets.Timestamp,
  'contract.name': DefaultShowFacets.ContractName,
  'contract.address': DefaultShowFacets.ContractAddress,
  'transaction.hash': DefaultShowFacets.TransactionHash,
  'event.name': DefaultShowFacets.EventName,
  'block.number': DefaultShowFacets.BlockNumber,
  'log.index': DefaultShowFacets.LogIndex,
  'entity.id': DefaultShowFacets.DistinctId
}

export const AccessorKeys = {
  [DefaultShowFacets.Severity]: 'severity',
  [DefaultShowFacets.ChainId]: 'chainId',
  [DefaultShowFacets.Timestamp]: 'timestamp',
  [DefaultShowFacets.ContractName]: 'contractName',
  [DefaultShowFacets.EventName]: 'eventName',
  [DefaultShowFacets.ContractAddress]: 'contractAddress',
  [DefaultShowFacets.Message]: 'highlightedMessage',
  [DefaultShowFacets.BlockNumber]: 'blockNumber',
  [DefaultShowFacets.TransactionHash]: 'transactionHash',
  [DefaultShowFacets.DistinctId]: 'distinctId',
  [DefaultShowFacets.LogIndex]: 'logIndex',
  [DefaultShowFacets.TransactionIndex]: 'transactionIndex'
}

export const DefaultEventLogColumns: Record<string, EventLogColumn> = {
  [DefaultShowFacets.Severity]: {
    id: DefaultShowFacets.Severity,
    name: 'Severity',
    size: 20,
    accessorKey: AccessorKeys[DefaultShowFacets.Severity],
    enableHiding: false,
    enableSorting: false,
    enableResizing: false
  },
  [DefaultShowFacets.Timestamp]: {
    id: DefaultShowFacets.Timestamp,
    name: 'Date',
    size: 150,
    accessorKey: AccessorKeys[DefaultShowFacets.Timestamp],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.EventName]: {
    id: DefaultShowFacets.EventName,
    name: 'Event',
    size: 100,
    accessorKey: AccessorKeys[DefaultShowFacets.EventName],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.ChainId]: {
    id: DefaultShowFacets.ChainId,
    name: 'Chain',
    size: 100,
    accessorKey: AccessorKeys[DefaultShowFacets.ChainId],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.ContractName]: {
    id: DefaultShowFacets.ContractName,
    name: 'Contract',
    size: 100,
    accessorKey: AccessorKeys[DefaultShowFacets.ContractName],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.ContractAddress]: {
    id: DefaultShowFacets.ContractAddress,
    name: 'Contract Address',
    size: 180,
    accessorKey: AccessorKeys[DefaultShowFacets.ContractAddress],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.BlockNumber]: {
    id: DefaultShowFacets.BlockNumber,
    name: 'Block Number',
    size: 100,
    accessorKey: AccessorKeys[DefaultShowFacets.BlockNumber],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.Message]: {
    id: DefaultShowFacets.Message,
    name: 'Message',
    size: 300,
    accessorKey: AccessorKeys[DefaultShowFacets.Message],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.DistinctId]: {
    id: DefaultShowFacets.DistinctId,
    name: 'Distinct ID',
    size: 200,
    accessorKey: AccessorKeys[DefaultShowFacets.DistinctId],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.TransactionHash]: {
    id: DefaultShowFacets.TransactionHash,
    name: 'Transaction Hash',
    size: 200,
    accessorKey: AccessorKeys[DefaultShowFacets.TransactionHash],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.TransactionIndex]: {
    id: DefaultShowFacets.TransactionIndex,
    name: 'Transaction Index',
    size: 200,
    accessorKey: AccessorKeys[DefaultShowFacets.TransactionIndex],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  },
  [DefaultShowFacets.LogIndex]: {
    id: DefaultShowFacets.LogIndex,
    name: 'Index',
    size: 100,
    accessorKey: AccessorKeys[DefaultShowFacets.LogIndex],
    enableHiding: true,
    enableSorting: true,
    enableResizing: true
  }
}

export const defaultShowColumnNames = {
  [DefaultShowFacets.Severity]: 'Severity',
  [DefaultShowFacets.ChainId]: 'Chain',
  [DefaultShowFacets.Timestamp]: 'Date',
  [DefaultShowFacets.ContractName]: 'Contract',
  [DefaultShowFacets.EventName]: 'Event',
  [DefaultShowFacets.ContractAddress]: 'Contract Address',
  [DefaultShowFacets.Message]: 'Message'
}

export const defaultShowColumnSize = {
  [DefaultShowFacets.Severity]: 20,
  [DefaultShowFacets.ChainId]: 100,
  [DefaultShowFacets.Timestamp]: 150,
  [DefaultShowFacets.ContractName]: 100,
  [DefaultShowFacets.EventName]: 100,
  [DefaultShowFacets.ContractAddress]: 180,
  [DefaultShowFacets.Message]: 300,
  [DefaultShowFacets.BlockNumber]: 100
}

export const defaultShowColumns = {
  [DefaultShowFacets.Severity]: true,
  [DefaultShowFacets.Timestamp]: true,
  [DefaultShowFacets.EventName]: true,
  [DefaultShowFacets.ChainId]: true,
  [DefaultShowFacets.ContractName]: true,
  [DefaultShowFacets.ContractAddress]: true,
  [DefaultShowFacets.Message]: true
  // [DefaultShowFacets.BlockNumber]: false,
}

const LogLevel = memo(function LogLevel({ level }: { level: string }) {
  let color: string
  switch (level) {
    case 'error':
      color = 'bg-red-600'
      break
    case 'warn':
      color = 'bg-orange-600'
      break
    case 'critical':
      color = 'bg-red-700'
      break
    case 'debug':
      color = 'bg-gray-200'
      break
    case 'info':
    default:
      color = 'bg-daybreak-blue-200 dark:bg-daybreak-blue-400'
      break
  }

  return (
    <div className="flex h-full w-full items-center">
      <div className={classNames(color, 'ml-1 rounded')} style={{ height: 14, width: 3 }}></div>
    </div>
  )
})

const LogDate = memo(function LogDate({ date }: { date: string }) {
  const { tz } = useTimeRange()
  return (
    <div className="text-text-foreground flex h-full w-full items-center">
      {dayjs(parseInt(date)).tz(tz).format('MMM DD HH:mm:ss.SSS')}
    </div>
  )
})

const MessageCell = ({ value }: { value: string }) => {
  return <LinkifyText text={value} />
}

export const defaultShowColumnCells = {
  [DefaultShowFacets.Severity]: (info: any) => <LogLevel level={info.getValue()} />,
  [DefaultShowFacets.Timestamp]: (info: any) => <LogDate date={info.getValue()} />,
  [DefaultShowFacets.EventName]: (info: any) => String(info.getValue()),
  [DefaultShowFacets.ChainId]: (info: any) => CHAIN_MAP[info.getValue()],
  [DefaultShowFacets.ContractName]: (info: any) => String(info.getValue()),
  [DefaultShowFacets.ContractAddress]: (info: any) => (
    <HexNumber data={String(info.getValue())} copyable truncate={20} noCopyHint={true} autoTruncate={true} />
  ),
  [DefaultShowFacets.BlockNumber]: (info: any) => String(info.getValue()),
  [DefaultShowFacets.Message]: (info: any) => <MessageCell value={info.getValue()} />
}

export const defaultColumnCell = (info: any) => {
  let ret = ''
  try {
    ret = info?.getValue()
  } catch {
    // ignore
  }
  return <LinkifyText text={ret} />
}

export const defaultColumns: ColumnDef<any>[] = [
  {
    header: '',
    id: DefaultShowFacets.Severity,
    accessorKey: AccessorKeys[DefaultShowFacets.Severity],
    cell: defaultShowColumnCells[DefaultShowFacets.Severity],
    size: 20,
    enableSorting: false,
    enableResizing: false,
    minSize: 20
  },
  {
    header: 'Date',
    id: DefaultShowFacets.Timestamp,
    accessorKey: AccessorKeys[DefaultShowFacets.Timestamp],
    cell: defaultShowColumnCells[DefaultShowFacets.Timestamp],
    size: 150
  },
  {
    header: 'Event',
    id: DefaultShowFacets.EventName,
    accessorKey: AccessorKeys[DefaultShowFacets.EventName],
    cell: defaultShowColumnCells[DefaultShowFacets.EventName],
    size: 100
  },

  {
    header: 'Chain',
    id: DefaultShowFacets.ChainId,
    accessorKey: AccessorKeys[DefaultShowFacets.ChainId],
    cell: defaultShowColumnCells[DefaultShowFacets.ChainId],
    size: 100
  },
  {
    header: 'Contract',
    id: DefaultShowFacets.ContractName,
    accessorKey: AccessorKeys[DefaultShowFacets.ContractName],
    cell: defaultShowColumnCells[DefaultShowFacets.ContractName],
    size: 100
  },
  {
    header: 'Contract Address',
    id: DefaultShowFacets.ContractAddress,
    accessorKey: AccessorKeys[DefaultShowFacets.ContractAddress],
    cell: defaultShowColumnCells[DefaultShowFacets.ContractAddress],
    size: 180
  },
  {
    header: 'Block number',
    id: DefaultShowFacets.BlockNumber,
    accessorKey: AccessorKeys[DefaultShowFacets.BlockNumber],
    cell: defaultShowColumnCells[DefaultShowFacets.BlockNumber],
    size: 100
  },
  {
    header: 'Message',
    id: DefaultShowFacets.Message,
    // accessorFn: (row: EventLogEntry) => row.highlightedMessage || row.message,
    accessorKey: AccessorKeys[DefaultShowFacets.Message],
    cell: defaultShowColumnCells[DefaultShowFacets.Message],
    size: 300,
    enableSorting: false
  }
]
