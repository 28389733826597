/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"
import * as CommonCommon from "../../common/protos/common.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum DataSource {
  METRICS = "METRICS",
  EVENTS = "EVENTS",
  PRICE = "PRICE",
  FORMULA = "FORMULA",
  COHORTS = "COHORTS",
  SYSTEM_SQL = "SYSTEM_SQL",
}


type BaseQueryRequestQuery = {
  dataSource?: DataSource
  sourceName?: string
}

export type QueryRequestQuery = BaseQueryRequestQuery
  & OneOf<{ metricsQuery: CommonCommon.Query; eventsQuery: CommonCommon.SegmentationQuery; priceQuery: CommonCommon.PriceSegmentationQuery; systemSqlQuery: CommonCommon.SystemSQLQuery }>

export type QueryRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  timeRange?: CommonCommon.TimeRangeLite
  queries?: QueryRequestQuery[]
  formulas?: CommonCommon.Formula[]
  limit?: number
  offset?: number
  bypassCache?: boolean
  cachePolicy?: CommonCommon.CachePolicy
}


type BaseQueryResponseResult = {
  id?: string
  alias?: string
  dataSource?: DataSource
  computeStats?: CommonCommon.ComputeStats
}

export type QueryResponseResult = BaseQueryResponseResult
  & OneOf<{ matrix: CommonCommon.Matrix; error: string }>

export type QueryResponse = {
  results?: QueryResponseResult[]
}

export type ListCoinsRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  limit?: number
  offset?: number
  searchQuery?: string
}

export type ListCoinsResponse = {
  coins?: CommonCommon.CoinID[]
  computeStats?: CommonCommon.ComputeStats
}

export type RetentionRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  timeRange?: CommonCommon.TimeRangeLite
  query?: CommonCommon.RetentionQuery
}


type BaseRetentionResponse = {
  computeStats?: CommonCommon.ComputeStats
}

export type RetentionResponse = BaseRetentionResponse
  & OneOf<{ results: CommonCommon.RetentionMatrix; error: string }>

export class InsightsService {
  static Query(req: QueryRequest, initReq?: fm.InitReq): Promise<QueryResponse> {
    return fm.fetchReq<QueryRequest, QueryResponse>(`/api/v1/insights/${req["projectOwner"]}/${req["projectSlug"]}/query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListCoins(req: ListCoinsRequest, initReq?: fm.InitReq): Promise<ListCoinsResponse> {
    return fm.fetchReq<ListCoinsRequest, ListCoinsResponse>(`/api/v1/insights/${req["projectOwner"]}/${req["projectSlug"]}/coins?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
  static Retention(req: RetentionRequest, initReq?: fm.InitReq): Promise<RetentionResponse> {
    return fm.fetchReq<RetentionRequest, RetentionResponse>(`/api/v1/insights/${req["projectOwner"]}/${req["projectSlug"]}/retention`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}