import { Dashboard, DashboardExtra } from 'gen/service/web'
import { EventLogEntry } from '@sentio/service/common'
import { atom } from 'jotai'

export const dashboardAtom = atom<Dashboard | null>(null)
export const templateVariablesAtom = atom<DashboardExtra['templateVariables']>((get) => {
  const dashboard = get(dashboardAtom)
  return dashboard?.extra?.templateVariables ?? {}
})
export const eventlogEntry = atom<EventLogEntry | undefined>(undefined)
