import { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { EChartsHandle, EChartsOption, ReactEChartsBase } from './EchartsBase'
import { alignTime, dateTimeToString, fromNumber, pickRangeByInterval, pickRangeByTimeRange, toDayjs } from 'lib/time'
import YaxisControls from './options/YaxisControls'
import { ChartConfig, ChartConfigCalculation, ChartDataSourceType } from 'gen/service/web'
import { computeSeries, dateRangeOfSeries, Series, SeriesData } from 'lib/metrics/series'
import { ChartProps } from './Chart'
import { defaults, isArray, isEqual, isString, uniq } from 'lodash'
import { defaultConfig as DefaultValueConfig } from './options/ValueOptions'
import { valueFormatter } from 'lib/metrics/formatter'
import { flip, FloatingOverlay, FloatingPortal, shift, useFloating } from '@floating-ui/react'
import { useBoolean } from 'lib/util/use-boolean'
import { ChevronDownIcon, DocumentMagnifyingGlassIcon, UserCircleIcon } from '@heroicons/react/24/outline'
import { useLogUrls } from 'lib/data/use-logs'
import router from 'next/router'
import { PopupMenuButton } from '../menu/PopupMenuButton'
import { HiOutlineCubeTransparent } from 'react-icons/hi'
import { HiOutlineClock } from 'react-icons/hi2'
import { HexNumber } from 'components/common/tags/HexNumber'
import { QueryRangeContext } from 'lib/context/query-range-context'
import classNames from 'lib/classnames'
import { atom, useAtomValue } from 'jotai'
import { templateVariablesAtom } from 'components/dashboard/atoms'
import { durationToSeconds, shiftTimezone, timeBefore } from '../../lib/time'
import { DataSource } from 'gen/service/insights'
import { genCohortQuery } from 'lib/data/use-query-cohorts'
import { SegmentationQuery } from 'gen/service/common'
import { ChartTooltip } from './ChartTooltip'
import ReactDOMServer from 'react-dom/server'
import { NumberFormat } from '../../lib/number'
import { useAsyncComputeSeries } from 'lib/metrics/series-async'

function roundByDPR(value) {
  const dpr = window.devicePixelRatio || 1
  return Math.round(value * dpr) / dpr
}

function onClickPreventDefault(e: React.MouseEvent) {
  e.preventDefault()
  e.stopPropagation()
}

const closestNumber = (arr: number[] | undefined, needle: number) => {
  return arr?.reduce((a, b) => (Math.abs(b - needle) < Math.abs(a - needle) ? b : a))
}

interface Mark {
  value?: number
  label?: string
  above?: boolean
  below?: boolean
  color?: string
  from?: Date
  to?: Date
}

export type MarkLine = Mark
export type MarkArea = Omit<Mark, 'above' | 'below' | 'value'>

const initialConfig: ChartConfig = {
  yAxis: {
    min: '',
    max: '',
    scale: true
  }
}

const yAxisToChartOption = (option?, markLines: MarkLine[] = []) => {
  const { min, max, scale } = option || {}
  const lineValues = markLines.map((m) => m.value).filter((v) => v !== undefined) as number[]
  const _min = Math.min(...lineValues, +min || Infinity)
  const _max = Math.max(...lineValues, max)
  return {
    min: (value: { min: number; max: number }) => (_min < value.min ? _min : undefined) as any,
    max: (value: { min: number; max: number }) => (value.max < _max ? _max : undefined) as any,
    scale
  }
}

const LabelsetMaps = {
  contract_name: 'contract',
  contract_address: 'address',
  value: 'value',
  event_name: 'event_name',
  chain: 'chain'
}
const convertLabelsets = (data: Record<string, string>, includeKeys?: string[], isEvent?: boolean) => {
  const result = {}
  if (isEvent) {
    Object.keys(data).forEach((key) => {
      if (data[key]) {
        result[key] = data[key]
      }
    })
  } else {
    Object.keys(LabelsetMaps).forEach((key) => {
      if (data[key]) {
        result[LabelsetMaps[key]] = data[key]
      }
    })
  }
  includeKeys?.forEach((key) => {
    if (data[key]) {
      result[key] = data[key]
    }
  })
  return result
}
const getEventName = (name: string) => {
  let eventName: undefined | string = name.split('-')[0].trim()
  if (eventName.toLowerCase() === 'all events') {
    eventName = undefined
  }

  return eventName
}
const isEventChart = (source: any) => {
  return source === DataSource.EVENTS || source === DataSource.COHORTS
}

/**
 * ECharts does not support time series with gaps, make sure all the series have same time series
 * @param series source series
 * @returns
 */
export const fixTimeSeries = (series: Series<Date>[]): void => {
  if (series.length < 2 || uniq(series.map((s) => s.data.length)).length === 1) {
    return
  }

  const timeIndex: any[] = []
  const dataIndexes = new Array(series.length).fill(0)
  while (dataIndexes.some((d, index) => d < series[index].data.length)) {
    const minTime = Math.min(...dataIndexes.map((d, index) => series[index].data[d]?.[0].getTime() ?? Infinity))
    timeIndex.push(new Date(minTime))
    dataIndexes.forEach((d, index) => {
      if (series[index].data[d]?.[0].getTime() === minTime) {
        dataIndexes[index]++
      }
    })
  }

  series.forEach((s) => {
    const data = s.data
    const fixedData: SeriesData<Date>[] = []
    let index = 0
    timeIndex.forEach((t) => {
      if (isEqual(data[index]?.[0], t)) {
        fixedData.push(data[index])
        index++
      } else {
        fixedData.push([t, null])
      }
    })
    s.data = fixedData
  })
}

const TimeSeriesChart = forwardRef<EChartsHandle, ChartProps>((props: ChartProps, ref) => {
  const labelsRef = useRef<any>(null)
  const yAxisRef = useRef<any>(null)
  const seriesRef = useRef<any>(null)
  const {
    group,
    title,
    data,
    compareData,
    startTime,
    endTime,
    tz,
    minHeight,
    onSelectTimeRange,
    loading,
    controls,
    config,
    markAreas,
    markLines,
    onChangeConfig,
    style,
    chartType,
    allowClick,
    showSymbol,
    sourceType,
    getEventNameById,
    noLegend = false,
    onInitChart,
    chartDataRef
  } = props
  const [yAxis, setYAxis] = useState(config?.yAxis || initialConfig.yAxis)
  const [maximumSignificantDigits, setMaximumSignificantDigits] = useState(3)
  const { isShare } = useContext(QueryRangeContext) || {}
  const templateVariableKeys = useAtomValue<string[]>(
    useMemo(() => {
      return atom((get) => {
        const templateVariables = get(templateVariablesAtom) || {}
        return Object.keys(templateVariables)
      })
    }, [])
  )
  const [series, setSeries] = useState<Series<Date>[]>([])
  const [compareSeries, setCompareSeries] = useState<Series<Date>[]>([])
  const [legend, setLegend] = useState<string[]>([])

  useEffect(() => {
    setYAxis(config?.yAxis || initialConfig.yAxis)
  }, [config])

  const computeSeriesAsync = useAsyncComputeSeries()
  useEffect(() => {
    ;(async () => {
      const { series, legend, seriesToMetricLabels } = await computeSeriesAsync(
        data?.results || [],
        chartType,
        ChartConfigCalculation.ALL,
        showSymbol,
        '',
        tz
      )
      labelsRef.current = seriesToMetricLabels
      computeMarkLines(series, markLines || [])
      computeMarksAreas(series, markAreas || [])
      setMaximumSignificantDigits(3)
      if (config?.yAxis?.stacked) {
        fixTimeSeries(series)
        series.forEach((s) => {
          s.stack = 'Total'
          s.stackStrategy = config?.yAxis?.stacked
        })
      }
      if (config?.lineConfig) {
        series.forEach((s) => {
          s.lineStyle = {
            ...s.lineStyle,
            type: config?.lineConfig?.style?.toLowerCase() || 'solid'
          }
        })
      }
      if (allowClick) {
        series.forEach((serie) => {
          serie.emphasis =
            serie.type === 'bar'
              ? {
                  itemStyle: {
                    shadowColor: 'rgba(0, 0, 0, 0.3)',
                    shadowBlur: 10
                  }
                  // focus: 'series',
                  // blurScope: 'coordinateSystem',
                }
              : {
                  scale: 1.5
                  // focus: 'series',
                  // blurScope: 'coordinateSystem',
                }
        })
      }

      if (compareData) {
        const { series: compareSeries, legend: compareLegend } = computeSeries(
          compareData.results || [],
          chartType,
          ChartConfigCalculation.ALL,
          showSymbol,
          'compare',
          tz
        )
        for (const s of compareSeries) {
          s.lineStyle = {
            ...s.lineStyle,
            type: 'dotted'
          }
          s.xAxisIndex = 1
        }
        setCompareSeries(compareSeries)
      }
      setSeries((pre) => {
        if (isEqual(pre, series)) {
          return pre
        }
        return series
      })
      setLegend((pre) => {
        if (isEqual(pre, legend)) {
          return pre
        }
        return legend
      })
    })()
  }, [
    data,
    compareData,
    markAreas,
    markLines,
    chartType,
    config?.yAxis,
    config?.lineConfig,
    allowClick,
    showSymbol,
    tz
  ])

  const setYAxisWrap = (yAxis) => {
    setYAxis(yAxis)
    onChangeConfig?.({ ...config, yAxis })
  }

  const [returnedSeries, totalSeries] = useMemo(() => {
    let returnedLength = 0
    let totalLength = 0
    for (const r of data?.results || []) {
      if (r.matrix?.samples) {
        returnedLength += r.matrix.samples.length
        totalLength += r?.matrix?.totalSamples || 0
      }
    }
    return [returnedLength, totalLength]
  }, [data])

  const NF = NumberFormat({ notation: 'compact', maximumSignificantDigits })
  const NF_LARGE = NumberFormat({ notation: 'scientific' })
  let yAxisLabel: number | undefined

  const [dataStart, dataEnd] = useMemo(() => dateRangeOfSeries(series), [series])
  const selectStart = useMemo(() => startTime && toDayjs(startTime, true).toDate(), [startTime])
  const selectEnd = useMemo(() => endTime && toDayjs(endTime, false).toDate(), [endTime])

  const numberFormatter = useMemo(() => {
    const valueConfig = defaults(config?.valueConfig || {}, DefaultValueConfig)
    return valueFormatter(valueConfig)
  }, [config?.valueConfig])

  const tooltipFormatter = useCallback(
    (params /*ticket, callback*/) => {
      if (allowClick) {
        yAxisRef.current = params.map((param) => ({
          value: param.value,
          seriesIndex: param.seriesIndex,
          seriesName: param.seriesName
        }))
      }
      return ReactDOMServer.renderToString(
        <ChartTooltip
          data={params}
          highlightSeriesId={global.highlightSeriesId}
          compareTimeDuration={config?.timeRangeOverride?.compareTime?.ago}
          numberFormatter={(v) => numberFormatter(v) as string}
        />
      )
    },
    [allowClick, numberFormatter, config, tz]
  )

  // the brush event will be fired from all grouped charts. So we need to check if the event is from this chart
  const [active, setActive] = useState(false)
  const onSelect = useCallback(
    (start: number, end: number) => {
      if (onSelectTimeRange && active) {
        let startTime = fromNumber(start)
        let endTime = fromNumber(end)
        const interval = config?.timeRangeOverride?.timeRange?.interval
        if (interval) {
          startTime = alignTime(startTime, interval, tz, 'start')
          endTime = alignTime(endTime, interval, tz, 'end')
        }
        onSelectTimeRange(startTime, endTime)
      }
    },
    [onSelectTimeRange, active]
  )

  const allowBrush = useMemo(() => {
    if (onSelectTimeRange && startTime && endTime) {
      const interval = config?.timeRangeOverride?.timeRange?.interval
      const diff = toDayjs(endTime).diff(toDayjs(startTime), 'seconds')
      return interval ? diff > durationToSeconds(interval) : true
    }
    return false
  }, [config?.timeRangeOverride?.timeRange?.interval, startTime, endTime, onSelectTimeRange])

  const xAxis = useMemo(() => {
    const selStart = selectStart ? shiftTimezone(selectStart, tz) : undefined
    const selEnd = selectEnd ? shiftTimezone(selectEnd, tz) : undefined
    const start = dataStart && selStart ? (dataStart.getTime() < selStart.getTime() ? dataStart : selStart) : selStart
    const end = dataEnd && selEnd ? (dataEnd.getTime() > selEnd.getTime() ? dataEnd : selEnd) : selEnd

    const ret = [
      {
        type: 'time',
        min: start,
        max: end,
        axisLabel: {
          hideOverlap: true,
          fontSize: 11
        }
      } as any
    ]
    if (config?.timeRangeOverride?.compareTime?.ago) {
      const d = config?.timeRangeOverride?.compareTime?.ago
      const compareStart = toDayjs(timeBefore(dayjs(start), d, true)).toDate()
      const compareEnd = toDayjs(timeBefore(dayjs(end), d, false)).toDate()
      ret.push({
        show: false,
        type: 'time',
        min: compareStart,
        max: compareEnd,
        axisLabel: {
          hideOverlap: true,
          fontSize: 11
        }
      } as any)
    }
    return ret as any
  }, [config?.timeRangeOverride?.compareTime, dataStart, selectStart, dataEnd, selectEnd, tz])

  const options: EChartsOption = useMemo(
    () => ({
      title: {
        text: title
      },
      grid: {
        top: title ? 48 : 16,
        right: 8,
        bottom: 8,
        left: 8,
        containLabel: true
      },
      xAxis,
      dataZoom: {
        type: 'inside',
        // startValue: selectStart,
        // endValue: selectEnd,
        zoomLock: true
      },
      legend: {
        data: legend,
        top: -10000,
        left: -10000
      },
      brush: allowBrush
        ? {
            xAxisIndex: 0
          }
        : undefined,
      toolbox: {
        show: false
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: function (value) {
            if (value == yAxisLabel && maximumSignificantDigits < 21) {
              // Increase precision if two labels are the same.
              setMaximumSignificantDigits(maximumSignificantDigits + 1)
            }
            yAxisLabel = value
            if (value > 1e21) {
              return NF_LARGE.format(value)
            }
            return NF.format(value)
          }
        },
        ...yAxisToChartOption(yAxis, markLines)
      },
      animation: false,
      series: (xAxis.length == 2 ? [...series, ...compareSeries] : series) as any,
      tooltip: {
        trigger: 'axis',
        confine: true,
        textStyle: {
          fontSize: 14,
          fontFamily: 'Roboto, Inter, sans-serif'
        },
        extraCssText:
          'max-width: 75%; max-height: 50vh; overflow-y: auto; padding: 10px 0; background-color: rgba(var(--text-background)); border-color: rgba(var(--border-color));',
        // ECharts has a textStyle.width option, but doesn't work. See https://github.com/apache/echarts/issues/14211. So use a custom renderer to truncate seriesName here.
        formatter: tooltipFormatter
      }
    }),
    [title, xAxis, legend, allowBrush, yAxis, series, compareSeries, tooltipFormatter, maximumSignificantDigits]
  )

  const { value: isOpen, setFalse: setClose, setTrue: setOpen } = useBoolean(false)
  const clickEventParamsRef = useRef<any>(null)
  const [selectedSeriesIndex, setSelectedSeriesIndex] = useState(0)
  const chartPositionRef = useRef<HTMLDivElement>(null)
  const { x, y, strategy, floating, refs } = useFloating({
    open: isOpen,
    placement: 'right',
    middleware: [flip(), shift()]
  })
  const onClick = useCallback(
    (params, rawParams) => {
      if (!params) {
        setClose()
        return
      }
      const baseBoundingRect = chartPositionRef.current?.getBoundingClientRect()
      if (params.event) {
        const { event } = params
        clickEventParamsRef.current = params
        const targetBoundRect = event.target.getBoundingRect()
        refs.setPositionReference({
          getBoundingClientRect: () => {
            return {
              x: event.offsetX + baseBoundingRect?.x,
              y: event.offsetY + baseBoundingRect?.y,
              width: targetBoundRect.width,
              height: targetBoundRect.height,
              top: event.offsetY + baseBoundingRect?.top,
              right: event.offsetX + baseBoundingRect?.right,
              bottom: event.offsetY + baseBoundingRect?.bottom,
              left: event.offsetX + baseBoundingRect?.left
            }
          }
        })
      } else if (isArray(params)) {
        const targetValue = closestNumber(
          yAxisRef.current?.map((item) => item.value[1]),
          params[1]
        )
        clickEventParamsRef.current = yAxisRef.current?.find((item) => item.value[1] === targetValue)
        refs.setPositionReference({
          getBoundingClientRect: () => {
            return {
              x: rawParams.offsetX + baseBoundingRect?.x,
              y: rawParams.offsetY + baseBoundingRect?.y,
              width: 10,
              height: 10,
              top: rawParams.offsetY + baseBoundingRect?.top,
              right: rawParams.offsetX + baseBoundingRect?.right,
              bottom: rawParams.offsetY + baseBoundingRect?.bottom,
              left: rawParams.offsetX + baseBoundingRect?.left
            }
          }
        })
      }
      setOpen()
    },
    [refs, setClose, setOpen]
  )

  const eventNameMapRef = useRef<Map<string, string>>(new Map())
  const eventsQueryMapRef = useRef<Map<string, SegmentationQuery>>(new Map())
  useEffect(() => {
    const map = new Map()
    const queryMap = new Map()
    if (chartDataRef?.current) {
      const { insightsQueries } = chartDataRef.current
      insightsQueries?.forEach((query) => {
        if (isEventChart(query.dataSource)) {
          if (query.eventsQuery?.id) {
            queryMap.set(query.eventsQuery.id, query.eventsQuery)
          }
          if (query.eventsQuery?.id && isString(query.eventsQuery?.resource?.name)) {
            map.set(query.eventsQuery.id, query.eventsQuery.resource.name)
          }
        }
      })
    }
    eventNameMapRef.current = map
    eventsQueryMapRef.current = queryMap
  }, [config])

  const { generateLogUrl } = useLogUrls()
  const onViewLog = useCallback(() => {
    const interval = config?.timeRangeOverride?.timeRange?.interval
    if (clickEventParamsRef.current && labelsRef.current) {
      const { seriesIndex, value } = clickEventParamsRef.current
      if (!value) {
        return
      }
      const idx = selectedSeriesIndex == null ? seriesIndex : selectedSeriesIndex
      const { labels = {}, name = '', id } = labelsRef.current[idx]
      const currentTime = dayjs(value[0])
      const range = interval
        ? pickRangeByInterval(currentTime, interval)
        : pickRangeByTimeRange(currentTime, startTime, endTime)
      const isEventSeries = eventNameMapRef.current.has(id) || sourceType === ChartDataSourceType.ANALYTICS

      // analytics add value range
      if (sourceType === ChartDataSourceType.ANALYTICS) {
        try {
          const eventName = getEventNameById?.(id)
          labels.event_name = eventName == undefined ? getEventName(name) : eventName
        } catch {
          //do nothing
        }
      } else if (eventNameMapRef.current.has(id)) {
        if (eventNameMapRef.current.get(id)) {
          labels.event_name = eventNameMapRef.current.get(id)
        }
      }

      const url = generateLogUrl(
        '',
        {
          from: dateTimeToString(range.startTime),
          to: dateTimeToString(range.endTime)
        },
        convertLabelsets(labels, templateVariableKeys, isEventSeries)
      )
      router.push(url)
    }
  }, [
    startTime,
    endTime,
    sourceType,
    generateLogUrl,
    selectedSeriesIndex,
    templateVariableKeys,
    config?.timeRangeOverride?.timeRange?.interval
  ])
  const onViewUser = useCallback(() => {
    const interval = config?.timeRangeOverride?.timeRange?.interval
    if (clickEventParamsRef.current && labelsRef.current) {
      const { seriesIndex, value } = clickEventParamsRef.current
      if (!value) {
        return
      }
      const idx = selectedSeriesIndex == null ? seriesIndex : selectedSeriesIndex
      const { name = '', id } = labelsRef.current[idx]
      const currentTime = dayjs(value[0])
      const range = interval
        ? pickRangeByInterval(currentTime, interval)
        : pickRangeByTimeRange(currentTime, startTime, endTime)
      let eventName: string | undefined
      const query = eventsQueryMapRef.current.get(id)
      if (sourceType === ChartDataSourceType.ANALYTICS) {
        try {
          const _eventName = getEventNameById?.(id)
          eventName = _eventName == undefined ? getEventName(name) : _eventName
        } catch {
          //do nothing
        }
      } else if (eventNameMapRef.current.has(id)) {
        eventName = eventNameMapRef.current.get(id)
      }
      if (eventName !== undefined && range) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone
        const req = genCohortQuery(
          eventName,
          {
            start: dateTimeToString(range.startTime),
            end: dateTimeToString(range.endTime),
            timezone: tz
          },
          query?.selectorExpr
        )
        const { owner, slug } = router.query
        window.open(`/${owner}/${slug}/accounts?query=${JSON.stringify(req)}`, '_blank')
      }
    }
  }, [selectedSeriesIndex, config?.timeRangeOverride?.timeRange?.interval, getEventNameById])

  const onSeriesEvent = useCallback((event, params) => {
    switch (event) {
      case 'click':
        setSelectedSeriesIndex(params.seriesIndex)
        break
      case 'mouseover':
        global.highlightSeriesId = params.seriesId
        document.querySelectorAll(`.series_${params.seriesId}`).forEach((node) => {
          node.classList.add('highlighted')
        })
        break
      case 'mouseout':
        // eslint-disable-next-line no-case-declarations
        const previousSeriesId = global.highlightSeriesId
        if (previousSeriesId) {
          document.querySelectorAll(`.series_${previousSeriesId}`).forEach((node) => {
            node.classList.remove('highlighted')
          })
        }
        global.highlightSeriesId = ''
    }
  }, [])

  const onSelectSeries = useCallback(
    (seriesIndex: number) => {
      clickEventParamsRef.current = {
        seriesIndex: seriesIndex,
        seriesName: series[seriesIndex].name
      }
      if (yAxisRef.current[seriesIndex]?.value) {
        clickEventParamsRef.current.value = yAxisRef.current[seriesIndex].value
      }
      setSelectedSeriesIndex(seriesIndex)
    },
    [series, yAxisRef, setSelectedSeriesIndex]
  )

  const switchSeries = useMemo(() => {
    const items: IMenuItem[][] = [series.map((serie, index) => ({ label: serie.name || '', key: `${index}` }))]
    return (
      <PopupMenuButton
        onSelect={(d) => onSelectSeries(parseInt(d, 10))}
        items={items}
        buttonIcon={(open) => (
          <span
            className={classNames(
              'text-primary-100',
              'hover:bg-primary-200/80 inline-block cursor-pointer rounded-md px-1 py-0.5',
              open ? 'bg-primary-200/80' : ''
            )}
          >
            <ChevronDownIcon className="h-3.5 w-3.5" />
          </span>
        )}
        portal={false}
        placement="bottom-end"
        itemsClassName="max-h-[50vh] overflow-y-auto"
      />
    )
  }, [series])

  const isEventSeriesClicked = useMemo(() => {
    if (!isOpen || !allowClick) {
      return false
    }
    let isEventSeriesClicked = false
    try {
      if (clickEventParamsRef.current && labelsRef.current) {
        const { seriesIndex, value } = clickEventParamsRef.current
        if (value) {
          const idx = selectedSeriesIndex == null ? seriesIndex : selectedSeriesIndex
          const { id } = labelsRef.current[idx] || {}
          isEventSeriesClicked = eventNameMapRef.current.has(id) || sourceType === ChartDataSourceType.ANALYTICS
        }
      }
    } catch {
      isEventSeriesClicked = false
    }
    return isEventSeriesClicked
  }, [isOpen, allowClick])

  return (
    <div
      className="h-full w-full"
      onMouseOver={() => {
        setActive(true)
      }}
      onMouseOut={() => {
        setActive(false)
      }}
    >
      <ReactEChartsBase
        ref={ref}
        loading={loading}
        group={group}
        option={options}
        minHeight={minHeight}
        returnedSeries={returnedSeries}
        totalSeries={totalSeries}
        onSelect={onSelect}
        onClick={allowClick ? onClick : undefined}
        style={style}
        onSeriesEvent={onSeriesEvent}
        noLegend={noLegend}
        onInitChart={onInitChart}
      />
      {controls && <YaxisControls yAxis={yAxis} setYAxis={setYAxisWrap} />}
      <div className="absolute left-0 top-0" ref={chartPositionRef}></div>
      {isOpen && allowClick && (
        <FloatingPortal>
          <FloatingOverlay className="z-50 bg-gray-100/20" onClick={setClose}>
            <div
              ref={floating}
              className="dark:bg-sentio-gray-100 absolute w-64 divide-y rounded-md bg-white pb-2 shadow"
              style={{
                position: strategy,
                top: 0,
                left: 0,
                transform: `translate(${roundByDPR(x || 0)}px,${roundByDPR(y || 0)}px)`
              }}
              onClick={onClickPreventDefault}
            >
              <div className="bg-primary-400 relative space-y-1 rounded-t-md px-2 py-2 text-white">
                <div className="text-ilabel font-ilabel">{isEventSeriesClicked ? 'Event Node' : 'Metric Node'}</div>
                <div className="flex w-full items-center">
                  <HiOutlineCubeTransparent className="mr-2 inline-block h-4 w-4" />
                  <div className="flex-1 truncate text-xs">
                    <HexNumber data={series[selectedSeriesIndex]?.name} truncate={30} copyable={true} />
                  </div>
                </div>
                {series.length > 1 ? <div className="absolute right-1 top-1">{switchSeries}</div> : null}
                {clickEventParamsRef.current?.value?.[0] && (
                  <div className="text-ichart flex items-center">
                    <HiOutlineClock className="mr-2 inline-block h-4 w-4" />
                    {dayjs(clickEventParamsRef.current.value[0]).format('LLL')}
                  </div>
                )}
              </div>
              <button
                className={classNames(
                  'text-ilabel font-ilabel flex w-full items-center gap-2 px-2 py-2',
                  isShare ? 'text-gray/50 cursor-not-allowed' : 'hover:text-primary text-gray-800 hover:bg-gray-100'
                )}
                onClick={onViewLog}
                disabled={isShare}
              >
                <DocumentMagnifyingGlassIcon className="h-4.5 w-4.5" />
                <span className="flex-grow truncate text-left">View related logs</span>
              </button>
              {isEventSeriesClicked ? (
                <button
                  className={classNames(
                    'text-ilabel font-ilabel flex w-full items-center gap-2 px-2 py-2',
                    isShare ? 'text-gray/50 cursor-not-allowed' : 'hover:text-primary text-gray-800 hover:bg-gray-100'
                  )}
                  onClick={onViewUser}
                  disabled={isShare}
                >
                  <UserCircleIcon className="h-4.5 w-4.5" />
                  <span className="flex-grow truncate text-left">View users</span>
                </button>
              ) : null}
            </div>
          </FloatingOverlay>
        </FloatingPortal>
      )}
    </div>
  )
})

TimeSeriesChart.displayName = 'TimeSeriesChart'

export default TimeSeriesChart

function computeMarkLines(series: any[], markLines: MarkLine[]) {
  if (markLines && markLines.length > 0 && series.length > 0) {
    series[0] = {
      ...series[0],
      markLine: {
        symbol: [],
        data: markLines.map((area) => {
          if (area.from || area.to) {
            return [
              {
                name: 'threshold',
                xAxis: area.from,
                yAxis: area.value ?? 'min',
                symbol: 'react',
                symbolSize: [20, 1],
                symbolOffset: [area.below ? -10 : 10, 0],
                label: {
                  formatter: `${area.label}`,
                  position: area.below ? 'insideMiddleBottom' : 'insideMiddleTop',
                  color: area.color || '#ff0000'
                },
                lineStyle: {
                  color: area.color || '#ff0000'
                }
              },
              {
                symbol: 'rect',
                symbolSize: [20, 1],
                symbolOffset: [area.below ? 10 : -10, 0],
                xAxis: area.to ? area.to : undefined,
                yAxis: area.value ?? 'max'
              }
            ]
          } else {
            return {
              name: 'threshold',
              yAxis: area.value,
              symbol: 'react',
              symbolSize: [20, 1],
              symbolOffset: [area.below ? -10 : 10, 0],
              label: {
                formatter: `${area.label}`,
                position: area.below ? 'insideStartBottom' : 'insideStartTop',
                color: area.color || '#ff0000'
              },
              lineStyle: {
                color: area.color || '#ff0000'
              }
            }
          }
        })
      }
    }
  }
}

function computeMarksAreas(series: any[], markAreas: MarkArea[]) {
  if (markAreas && markAreas.length > 0 && series.length > 0) {
    series[0].markArea = {
      itemStyle: {
        color: markAreas[0].color || 'rgba(255, 173, 177, 0.4)'
      }
    }
    series[0].markArea.data = markAreas.map((markArea) => {
      return [
        {
          xAxis: markArea.from ? markArea.from : undefined
        },
        {
          xAxis: markArea.to ? markArea.to : undefined
        }
      ]
    })
  }
}
