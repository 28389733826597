import { Fragment, useMemo } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import classNames from '../../lib/classnames'
import { Duration } from '@sentio/service/common'
import { durationToLongString } from '../../lib/time'

dayjs.extend(utc)
dayjs.extend(timezone)

interface Props {
  data: any
  compareTimeDuration?: Duration
  numberFormatter: (value: number) => string
  highlightSeriesId?: string
}

export function ChartTooltip({ data, numberFormatter, compareTimeDuration, highlightSeriesId }: Props) {
  const { series, hasCompare, hasCurrent, currentTime, compareTime } = useMemo(() => {
    const params = data.sort((p1, p2) => p2.value[1] - p1.value[1])
    const seriesData: Record<string, any> = {}
    let currentTime: dayjs.Dayjs | undefined
    let compareTime: dayjs.Dayjs | undefined
    for (const p of params) {
      const { marker, seriesName, value, seriesId } = p
      if (seriesId.endsWith('_compare')) {
        const id = seriesId.replace('_compare', '')
        if (compareTime === undefined) {
          compareTime = dayjs(value[0])
        }
        seriesData[id] = {
          seriesId: id,
          ...seriesData[id],
          compareValue: value[1],
          compareTime: value[0],
          marker,
          seriesName
        }
      } else {
        if (currentTime === undefined) {
          currentTime = dayjs(value[0])
        }
        seriesData[seriesId] = {
          seriesId,
          ...seriesData[seriesId],
          time: value[0],
          value: value[1],
          marker,
          seriesName
        }
      }
    }
    const series = Object.values(seriesData).sort((a, b) => b.value - a.value)
    const hasCompare = series[0]?.compareValue !== undefined
    const hasCurrent = series[0]?.value !== undefined
    if (compareTimeDuration && compareTime && !currentTime) {
      currentTime = compareTime.add(compareTimeDuration.value!, compareTimeDuration.unit as any)
    }
    return { series, hasCompare, currentTime, compareTime, hasCurrent }
  }, [data])

  const renderRow = (p: any, idx: number) => {
    const { marker, seriesName, compareValue, compareTime, value, seriesId } = p
    const highlighted = seriesId === highlightSeriesId
    // const diff = undefined
    const diff =
      hasCompare && hasCurrent && compareValue != null && value != null
        ? (value - compareValue) / compareValue
        : undefined

    return (
      <Fragment key={idx}>
        <div
          key={idx}
          className={classNames(
            'sentio-tooltip-item series-name truncate text-gray-700',
            highlighted ? 'highlighted' : ''
          )}
          style={{ minWidth: '4rem' }}
        >
          <span dangerouslySetInnerHTML={{ __html: marker }}></span>
          {seriesName}
        </div>

        <div
          key={`${idx}-value`}
          className={classNames(
            'sentio-tooltip-item min-w-[4rem] truncate pl-1 text-right font-semibold',
            highlighted ? 'highlighted' : ''
          )}
        >
          {hasCurrent ? numberFormatter(value) : '-'}
          {diff !== undefined && !isNaN(diff) && (
            <span className={classNames('ml-1 text-xs', diff > 0 ? 'text-green-500' : 'text-red')}>
              {diff > 0 ? '+' : ''}
              {(diff * 100).toFixed(2)}%
            </span>
          )}
        </div>
      </Fragment>
    )
  }

  const renderCompareRow = (p: any, idx: number) => {
    const { marker, seriesName, compareValue, seriesId } = p
    const highlighted = seriesId === highlightSeriesId

    return (
      <Fragment key={idx}>
        <div
          key={idx}
          className={classNames(
            'sentio-tooltip-item sentio-tooltip-compare-item series-name truncate text-gray-500',
            highlighted ? 'highlighted' : ''
          )}
          style={{ minWidth: '4rem' }}
        >
          <span dangerouslySetInnerHTML={{ __html: marker }}></span>
          {seriesName}
        </div>

        <div
          key={`${idx}-value`}
          className={classNames(
            'sentio-tooltip-item min-w-[4rem] truncate pl-1 text-right font-semibold text-gray-500',
            highlighted ? 'highlighted' : ''
          )}
        >
          {compareValue ? numberFormatter(compareValue) : '-'}
        </div>
      </Fragment>
    )
  }

  return (
    <div className={classNames('grid w-full px-2 ')} style={{ gridTemplateColumns: '1fr auto' }}>
      <div className={classNames('pl-2', 'dark:text-text-foreground-secondary col-span-2 text-left')}>
        {currentTime?.format('YYYY-MM-DD HH:mm:ss')}
      </div>
      {series.map((s, idx) => renderRow(s, idx))}
      {hasCompare && compareTimeDuration && (
        <>
          <div className={classNames('mt-2 pl-2', 'col-span-2 text-left text-gray-500')}>
            {compareTime?.format('YYYY-MM-DD HH:mm:ss')} (previous {durationToLongString(compareTimeDuration)})
          </div>
          {series.map((s, idx) => renderCompareRow(s, idx))}
        </>
      )}
    </div>
  )
}
