import { DateTimeValue, durationToSeconds, parseDuration, toDayjs } from './time'
import { Duration } from '../gen/service/common'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export function calculateStep(start: DateTimeValue, end: DateTimeValue): Duration {
  const diff = toDayjs(end, false).diff(toDayjs(start, true))
  const ret = roundInterval(diff)
  return parseDuration(ret)
}

export function calculateInterval(start: DateTimeValue, end: DateTimeValue): number {
  return durationToSeconds(calculateStep(start, end))
}

// roundInterval rounds the interval to the nearest interval that is less than diff / 1000
export function roundInterval(diff: number) {
  switch (true) {
    case diff <= dayjs.duration(15, 'minute').asMilliseconds():
      return '10s'
    case diff <= dayjs.duration(1, 'day').asMilliseconds():
      return '1m'
    case diff <= dayjs.duration(30, 'day').asMilliseconds():
      return '1h'
    case diff <= dayjs.duration(2, 'years').asMilliseconds():
      return '1d'
    case diff <= dayjs.duration(10, 'years').asMilliseconds():
    default:
      return '7d'
  }
}
