import { useRef, useState, useEffect, useCallback } from 'react'

function generateCryptoId() {
  return 'id-' + crypto.getRandomValues(new Uint32Array(1))[0].toString(36)
}

type CallbackFnType = (data: any) => void

let worker: Worker

if (typeof window !== 'undefined') {
  worker = new Worker(new URL('./worker.ts', import.meta.url))
}

export const useGlobalWorker = () => {
  const workerIdRef = useRef<string>(generateCryptoId())
  const callbackFunctions = useRef<CallbackFnType[]>([])

  useEffect(() => {
    return () => {
      callbackFunctions.current.forEach((callback) => {
        worker.removeEventListener('message', callback)
      })
    }
  }, [])

  const postMessage = useCallback((eventName: string, data: any) => {
    if (!worker) {
      return
    }
    worker.postMessage({ event: `${workerIdRef.current}.${eventName}`, data })
  }, [])

  const addListener = useCallback((eventName: string, callbackFn: (data: any) => void) => {
    if (!worker) {
      return
    }
    const callback = (event: MessageEvent) => {
      if (event.data.event === `${workerIdRef.current}.${eventName}`) {
        callbackFn(event.data.data)
      }
    }
    worker.addEventListener('message', callback)
    callbackFunctions.current.push(callback)
  }, [])

  const postMessageAsync = useCallback((eventName: string, data: any): Promise<any> => {
    return new Promise((resolve) => {
      const callbackId = `${generateCryptoId()}.${eventName}`
      const callback = (event: MessageEvent) => {
        if (event.data.event === callbackId) {
          resolve(event.data.data)
          worker.removeEventListener('message', callback)
        }
      }
      worker.addEventListener('message', callback)
      worker.postMessage({ event: callbackId, data })
    })
  }, [])

  const terminate = useCallback(() => {
    worker.terminate()
  }, [])

  return {
    postMessage,
    postMessageAsync,
    addListener,
    terminate
  }
}
