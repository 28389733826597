import { ColumnDef, SortingState } from '@tanstack/react-table'
import { atom, useAtom } from 'jotai'
import { useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { ChartTypeLimits, ChartTypeLimitsMax } from './use-query-range'
import { ChartChartType } from '../../gen/service/web'

export type TableState = {
  sorting: SortingState
  columnOrders: string[]
  showColumns: { [key: string]: boolean }
}

const tableStateAtom = atom({} as { [key: string]: TableState })

export function useTableState(
  id: string,
  columns: ColumnDef<any>[],
  defaultState?: TableState,
  onStateChange?: (config: TableState) => void
) {
  const [tableStates, setTableStates] = useAtom(tableStateAtom)
  const tableState = useMemo(() => tableStates[id] || ({} as TableState), [tableStates, id])
  const columnSet = useMemo(() => new Set(columns.map((c) => c.id)), [columns])

  const sortingState = useMemo(
    () => (tableState.sorting || defaultState?.sorting || []).filter((s) => columnSet.has(s.id)),
    [tableState, columns]
  )

  const columnOrders = useMemo(() => {
    const orders = tableState.columnOrders || defaultState?.columnOrders || []

    function getIndex(a) {
      if (a === '__index') {
        return 0
      }
      return orders.indexOf(a)
    }

    return columns
      .map((c) => c.id!)
      .sort((a, b) => {
        let aIndex = getIndex(a)
        if (aIndex === -1) {
          aIndex = orders.length
        }
        let bIndex = getIndex(b)
        if (bIndex === -1) {
          bIndex = orders.length
        }
        if (aIndex === bIndex) {
          // return a.localeCompare(b, undefined, { numeric: true })
          // keep the order as it is
          return 1
        } else {
          return aIndex - bIndex
        }
      })
  }, [tableState, columns])

  const showColumns = useMemo(() => {
    const ret: { [key: string]: boolean } = {}
    const showColumns = tableState.showColumns || defaultState?.showColumns || {}
    if (Object.keys(showColumns).length === 0) {
      return columns.reduce((acc, c) => {
        acc[c.id!] = true
        return acc
      }, {})
    }
    for (const key in showColumns) {
      if (columnSet.has(key)) {
        ret[key] = showColumns[key]
      }
    }
    return ret
  }, [tableState, columns])

  const onChangeSorting = useCallback(
    (sorting: SortingState) => {
      changeState('sorting')(sorting)
    },
    [tableState, columns]
  )

  const onChangeColumnOrders = useCallback(
    (columnOrders: string[] | undefined) => {
      changeState('columnOrders')(columnOrders)
    },
    [tableState, columns]
  )

  const onShowColumnChange = useCallback(
    (column: string, show: boolean) => {
      changeState('showColumns')(
        produce(showColumns, (draft) => {
          draft[column] = show
        })
      )
    },
    [tableState, columns]
  )

  const changeState = (field: keyof TableState) => (value) => {
    const newState = produce(tableStates, (draft) => {
      const state = draft[id] || defaultState
      state[field] = value
      draft[id] = state
    })
    setTableStates(newState)
    onStateChange && onStateChange(newState[id])
  }

  const resetState = useCallback(() => {
    setTableStates(
      produce(tableStates, (draft) => {
        delete draft[id]
      })
    )
  }, [tableStates, id])

  return {
    sortingState,
    columnOrders,
    showColumns,
    onChangeSorting,
    onChangeColumnOrders,
    onShowColumnChange,
    resetState
  }
}

export function useTableLimit(chartType, chartConfig): number {
  return useMemo(() => {
    const ret = (chartType && ChartTypeLimits[chartType]) || 20
    const max = (chartType && ChartTypeLimitsMax[chartType]) || 20
    if (chartType === ChartChartType.TABLE) {
      const rowLimit = chartConfig?.tableConfig?.rowLimit
      if (rowLimit > 0 && rowLimit <= max) return rowLimit
    }
    return ret
  }, [chartType, chartConfig])
}
