import { forwardRef, useEffect, useMemo, useState } from 'react'
import { EChartsHandle, EChartsOption, ReactEChartsBase } from './EchartsBase'
import { Series } from 'lib/metrics/series'
import { ChartProps } from './Chart'
import { defaults, omitBy, isNull } from 'lodash'
import { defaultConfig } from './options/QueryValueControls'
import { useResizeDetector } from 'react-resize-detector'
import { ChartConfig } from 'gen/service/web'
import { useDarkMode } from 'lib/util/use-dark-mode'
import { useGlobalWorker } from 'lib/worker/use-global-worker'
import { eventName } from 'lib/worker/parsers/QueryValueChartParser'

export const QueryValueChart = forwardRef<EChartsHandle, ChartProps>((props: ChartProps, ref) => {
  const { postMessage, addListener } = useGlobalWorker()
  const { data, minHeight, loading, style, chartType, onInitChart } = props
  const config: ChartConfig = defaults(omitBy(props.config, isNull), { queryValueConfig: defaultConfig })
  const isDarkMode = useDarkMode()
  const [series, setSeries] = useState<Series<Date>[]>([])
  const [valueText, setValueText] = useState<string | undefined>()
  const [textColor, setTextColor] = useState<string | undefined>()
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>()

  useEffect(() => {
    addListener(eventName, (data) => {
      const { series, valueText, textColor, backgroundColor } = data
      setSeries(series)
      setValueText(valueText)
      setTextColor(textColor)
      setBackgroundColor(backgroundColor)
    })
  }, [])

  useEffect(() => {
    if (!data?.results) return
    postMessage(eventName, {
      data,
      chartType,
      config,
      isDarkMode
    })
  }, [data?.results, chartType, config?.queryValueConfig, isDarkMode])

  const { width, height, ref: ref2 } = useResizeDetector()

  const fontSize = useMemo(() => {
    return Math.min((width || 0) / String(valueText).length, (height || 0) / 1.5)
  }, [width, height, valueText])

  const options: EChartsOption = {
    backgroundColor,
    grid: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    },
    toolbox: {
      show: false
    },
    animation: false,
    series: series as any,
    xAxis: {
      type: 'time',
      show: false
    },
    yAxis: {
      type: 'value',
      show: false
    },
    legend: {
      show: false
    },
    graphic: {
      type: 'text',
      z: 100,
      left: 'center',
      top: 'middle',
      style: {
        text: valueText,
        fontSize,
        stroke: textColor,
        fill: textColor
      }
    }
  }

  return (
    <div className="h-full w-full">
      <div className="h-full w-full" ref={ref2}>
        <ReactEChartsBase
          ref={ref}
          loading={loading}
          // group={group}  bar gauge chart should have independent scroll
          option={options}
          minHeight={minHeight}
          style={style}
          noLegend
          onInitChart={onInitChart}
        />
      </div>
    </div>
  )
})
QueryValueChart.displayName = 'QueryValue'
