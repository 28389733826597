/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as fm from "../../../fetch.pb"
import * as GoogleProtobufEmpty from "../../../google/protobuf/empty.pb"
import * as GoogleProtobufStruct from "../../../google/protobuf/struct.pb"
import * as GoogleProtobufTimestamp from "../../../google/protobuf/timestamp.pb"
import * as CommonCommon from "../../common/protos/common.pb"

type Absent<T, K extends keyof T> = { [k in Exclude<keyof T, K>]?: undefined };
type OneOf<T> =
  | { [k in keyof T]?: undefined }
  | (
    keyof T extends infer K ?
      (K extends string & keyof T ? { [k in K]: T[K] } & Absent<T, K>
        : never)
    : never);

export enum FacetType {
  LIST = "LIST",
  RANGE = "RANGE",
}

export enum SQLGrammarVersion {
  AUTO = "AUTO",
  V1 = "V1",
  V2 = "V2",
  V3 = "V3",
}

export enum Source {
  SQL_EDITOR = "SQL_EDITOR",
  DASHBOARD = "DASHBOARD",
  ASYNC_TRIGGER = "ASYNC_TRIGGER",
  CURL = "CURL",
}

export enum ExecutionStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  KILLED = "KILLED",
}

export enum EventPropertyEventPropertyType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  LIST = "LIST",
  TIME = "TIME",
}

export enum TableTableType {
  RESERVED = "RESERVED",
  EVENT = "EVENT",
  METRICS = "METRICS",
  SUBGRAPH = "SUBGRAPH",
  MATERIALIZED_VIEW = "MATERIALIZED_VIEW",
  IMPORTED_EVENT = "IMPORTED_EVENT",
  SYSTEM = "SYSTEM",
  ENTITY = "ENTITY",
  IMPORTED_ENTITY = "IMPORTED_ENTITY",
  IMPORTED_SUBGRAPH = "IMPORTED_SUBGRAPH",
}

export enum TableColumnColumnType {
  STRING = "STRING",
  NUMBER = "NUMBER",
  BOOLEAN = "BOOLEAN",
  LIST = "LIST",
  TIME = "TIME",
}

export type SupersetRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
}

export type GetEventsRequest = {
  projectSlug?: string
  projectId?: string
  version?: number
}

export type GetEventsResponse = {
  events?: Event[]
  computeStats?: CommonCommon.ComputeStats
}

export type Event = {
  name?: string
  displayName?: string
  properties?: EventProperty[]
}

export type EventProperty = {
  name?: string
  displayName?: string
  type?: EventPropertyEventPropertyType
}

export type GetEventPropertyValuesRequest = {
  projectSlug?: string
  projectId?: string
  version?: number
  eventName?: string
  eventPropertyName?: string[]
  limit?: number
  searchQuery?: string
}

export type GetEventPropertyValuesResponseEventPropertyValue = {
  name?: string
  displayName?: string
  value?: CommonCommon.Any
}

export type GetEventPropertyValuesResponse = {
  values?: GetEventPropertyValuesResponseEventPropertyValue[]
  computeStats?: CommonCommon.ComputeStats
}

export type SegmentationRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  timeRange?: CommonCommon.TimeRangeLite
  queries?: CommonCommon.SegmentationQuery[]
  formulas?: CommonCommon.Formula[]
  systemSqlQueries?: CommonCommon.SystemSQLQuery[]
  debug?: boolean
  limit?: number
  offset?: number
}


type BaseQuerySegmentationResponseResult = {
  alias?: string
  id?: string
  computeStats?: CommonCommon.ComputeStats
}

export type QuerySegmentationResponseResult = BaseQuerySegmentationResponseResult
  & OneOf<{ matrix: CommonCommon.Matrix; error: string }>

export type QuerySegmentationResponse = {
  results?: QuerySegmentationResponseResult[]
}

export type CohortsRequestSort = {
  field?: string
  desc?: boolean
}

export type CohortsRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  query?: CommonCommon.CohortsQuery
  offset?: number
  limit?: number
  sorting?: CohortsRequestSort
  searchQuery?: string
  debug?: boolean
}

export type QueryCohortsResponseUser = {
  distinctId?: string
  updatedAt?: GoogleProtobufTimestamp.Timestamp
  chain?: string
  total?: number
}

export type QueryCohortsResponseResult = {
  name?: string
  displayName?: string
  total?: number
  values?: string[]
  executeCommand?: string
  users?: {[key: string]: QueryCohortsResponseUser}
}


type BaseQueryCohortsResponse = {
  computeStats?: CommonCommon.ComputeStats
}

export type QueryCohortsResponse = BaseQueryCohortsResponse
  & OneOf<{ result: QueryCohortsResponseResult; error: string }>

export type SaveCohortsResponse = {
  cohort?: Cohort
}

export type ListFacetsRequest = {
  projectId?: string
  version?: number
  projectOwner?: string
  projectSlug?: string
}

export type LogFacet = {
  name?: string
  displayName?: string
  path?: string
  facetType?: FacetType
  type?: string
  source?: string
  isBuiltIn?: boolean
}

export type ValueBucket = {
  buckets?: {[key: string]: number}
  othersCount?: string
}

export type ListFacetsResponse = {
  facets?: LogFacet[]
}

export type GetFacetRequest = {
  projectId?: string
  version?: number
  projectOwner?: string
  projectSlug?: string
  facetId?: string
  query?: string
  timeRange?: CommonCommon.TimeRange
  termFilter?: string
}

export type GetFacetResponse = {
  facet?: LogFacet
  buckets?: ValueBucket
  maxValue?: CommonCommon.Any
  minValue?: CommonCommon.Any
}

export type LogQueryRequestSort = {
  field?: string
  desc?: boolean
}

export type LogQueryRequestFilter = {
  field?: string
  value?: string
  not?: boolean
}

export type LogQueryRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  query?: string
  timeRange?: CommonCommon.TimeRange
  sorts?: LogQueryRequestSort[]
  after?: CommonCommon.Any[]
  limit?: number
  offset?: number
  filters?: LogQueryRequestFilter[]
  version?: number
}

export type LogQueryResponse = {
  entries?: CommonCommon.EventLogEntry[]
  after?: CommonCommon.Any[]
  total?: string
}

export type QueryLogMetricsResponse = {
  results?: QuerySegmentationResponseResult[]
}

export type SQLQuery = {
  sql?: string
  size?: number
  variables?: GoogleProtobufStruct.Struct
  version?: SQLGrammarVersion
}


type BaseSyncExecuteSQLRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  source?: Source
  bypassCache?: boolean
  cachePolicy?: CommonCommon.CachePolicy
}

export type SyncExecuteSQLRequest = BaseSyncExecuteSQLRequest
  & OneOf<{ sqlQuery: SQLQuery; cursor: string }>


type BaseSyncExecuteSQLResponse = {
  runtimeCost?: string
  computeStats?: CommonCommon.ComputeStats
}

export type SyncExecuteSQLResponse = BaseSyncExecuteSQLResponse
  & OneOf<{ result: CommonCommon.TabularData; error: string }>

export type SaveAndRunSQLRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  sqlQuery?: SQLQuery
  source?: Source
}


type BaseExecutionInfo = {
  queryId?: string
  executionId?: string
  status?: ExecutionStatus
  scheduledAt?: GoogleProtobufTimestamp.Timestamp
  startedAt?: GoogleProtobufTimestamp.Timestamp
  finishedAt?: GoogleProtobufTimestamp.Timestamp
  computeStats?: CommonCommon.ComputeStats
  processorVersion?: number
}

export type ExecutionInfo = BaseExecutionInfo
  & OneOf<{ result: CommonCommon.TabularData; error: string }>

export type SaveAndRunSQLResponse = {
  queueLength?: number
  executionInfo?: ExecutionInfo
}

export type ListSQLQueriesRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  limit?: number
  offset?: number
  includeExecutionResult?: boolean
  sources?: Source[]
}

export type ListSQLQueriesResponseQuery = {
  queryId?: string
  sqlQuery?: SQLQuery
  createdAt?: GoogleProtobufTimestamp.Timestamp
  lastExecutionInfo?: ExecutionInfo
  source?: Source
}

export type ListSQLQueriesResponse = {
  total?: number
  queries?: ListSQLQueriesResponseQuery[]
}

export type RerunSQLQueryRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  queryId?: string
  sqlQuery?: SQLQuery
}

export type RerunSQLQueryResponse = {
  queueLength?: number
  executionInfo?: ExecutionInfo
}

export type DeleteSQLQueryRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  queryId?: string
}

export type DeleteSQLQueryResponse = {
  empty?: GoogleProtobufEmpty.Empty
}

export type QuerySQLResultRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  executionId?: string
}

export type QuerySQLResultResponse = {
  executionInfo?: ExecutionInfo
}

export type CreateMaterializedViewRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  name?: string
  orderBy?: string
  sql?: string
  engine?: string
}

export type DeleteMaterializedViewRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  name?: string
}

export type QueryTablesRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
}

export type TableColumn = {
  name?: string
  columnType?: TableColumnColumnType
  clickhouseDataType?: string
  isBuiltin?: boolean
}

export type Table = {
  name?: string
  columns?: {[key: string]: TableColumn}
  tableType?: TableTableType
}

export type QueryTablesResponse = {
  tables?: {[key: string]: Table}
  computeStats?: CommonCommon.ComputeStats
}

export type Cohort = {
  query?: CommonCommon.CohortsQuery
  createdAt?: GoogleProtobufTimestamp.Timestamp
  updatedAt?: GoogleProtobufTimestamp.Timestamp
}

export type ListCohortsRequest = {
  projectOwner?: string
  projectSlug?: string
  projectId?: string
  version?: number
  limit?: number
  offset?: number
  searchQuery?: string
}

export type ListCohortsResponse = {
  cohorts?: Cohort[]
}

export class AnalyticService {
  static QuerySegmentation(req: SegmentationRequest, initReq?: fm.InitReq): Promise<QuerySegmentationResponse> {
    return fm.fetchReq<SegmentationRequest, QuerySegmentationResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/segmentation`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static GetEvents(req: GetEventsRequest, initReq?: fm.InitReq): Promise<GetEventsResponse> {
    return fm.fetchReq<GetEventsRequest, GetEventsResponse>(`/api/v1/analytics/events?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static GetEventPropertyValues(req: GetEventPropertyValuesRequest, initReq?: fm.InitReq): Promise<GetEventPropertyValuesResponse> {
    return fm.fetchReq<GetEventPropertyValuesRequest, GetEventPropertyValuesResponse>(`/api/v1/analytics/event/property/values?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static QueryCohorts(req: CohortsRequest, initReq?: fm.InitReq): Promise<QueryCohortsResponse> {
    return fm.fetchReq<CohortsRequest, QueryCohortsResponse>(`/api/v1/analytics/cohorts`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SaveCohorts(req: CohortsRequest, initReq?: fm.InitReq): Promise<SaveCohortsResponse> {
    return fm.fetchReq<CohortsRequest, SaveCohortsResponse>(`/api/v1/analytics/cohorts/save`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListCohorts(req: ListCohortsRequest, initReq?: fm.InitReq): Promise<ListCohortsResponse> {
    return fm.fetchReq<ListCohortsRequest, ListCohortsResponse>(`/api/v1/analytics/cohorts/list?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static DeleteCohorts(req: CohortsRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<CohortsRequest, GoogleProtobufEmpty.Empty>(`/api/v1/analytics/cohorts/delete`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ExecuteSQL(req: SyncExecuteSQLRequest, initReq?: fm.InitReq): Promise<SyncExecuteSQLResponse> {
    return fm.fetchReq<SyncExecuteSQLRequest, SyncExecuteSQLResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/execute`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static TestSQL(req: SyncExecuteSQLRequest, initReq?: fm.InitReq): Promise<SyncExecuteSQLResponse> {
    return fm.fetchReq<SyncExecuteSQLRequest, SyncExecuteSQLResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/test`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static SaveAndRunSQL(req: SaveAndRunSQLRequest, initReq?: fm.InitReq): Promise<SaveAndRunSQLResponse> {
    return fm.fetchReq<SaveAndRunSQLRequest, SaveAndRunSQLResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/save_and_run_query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListSQLQueries(req: ListSQLQueriesRequest, initReq?: fm.InitReq): Promise<ListSQLQueriesResponse> {
    return fm.fetchReq<ListSQLQueriesRequest, ListSQLQueriesResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/list_queries?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
  static RerunSQLQuery(req: RerunSQLQueryRequest, initReq?: fm.InitReq): Promise<RerunSQLQueryResponse> {
    return fm.fetchReq<RerunSQLQueryRequest, RerunSQLQueryResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/rerun_query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteSQLQuery(req: DeleteSQLQueryRequest, initReq?: fm.InitReq): Promise<DeleteSQLQueryResponse> {
    return fm.fetchReq<DeleteSQLQueryRequest, DeleteSQLQueryResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/delete_query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static QuerySQLResult(req: QuerySQLResultRequest, initReq?: fm.InitReq): Promise<QuerySQLResultResponse> {
    return fm.fetchReq<QuerySQLResultRequest, QuerySQLResultResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/query_result?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
  static GetClickhouseStatus(req: GoogleProtobufEmpty.Empty, initReq?: fm.InitReq): Promise<CommonCommon.ClickhouseStatus> {
    return fm.fetchReq<GoogleProtobufEmpty.Empty, CommonCommon.ClickhouseStatus>(`/api/v1/clickhouse_status?${fm.renderURLSearchParams(req, [])}`, {...initReq, method: "GET"})
  }
  static Superset(req: SupersetRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<SupersetRequest, GoogleProtobufEmpty.Empty>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/superset?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
  static QueryTables(req: QueryTablesRequest, initReq?: fm.InitReq): Promise<QueryTablesResponse> {
    return fm.fetchReq<QueryTablesRequest, QueryTablesResponse>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/tables?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
  static CreateMaterializedView(req: CreateMaterializedViewRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<CreateMaterializedViewRequest, GoogleProtobufEmpty.Empty>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/create_mv`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static DeleteMaterializedView(req: DeleteMaterializedViewRequest, initReq?: fm.InitReq): Promise<GoogleProtobufEmpty.Empty> {
    return fm.fetchReq<DeleteMaterializedViewRequest, GoogleProtobufEmpty.Empty>(`/api/v1/analytics/${req["projectOwner"]}/${req["projectSlug"]}/sql/delete_mv?${fm.renderURLSearchParams(req, ["projectOwner", "projectSlug"])}`, {...initReq, method: "GET"})
  }
}
export class SearchService {
  static ListFacets(req: ListFacetsRequest, initReq?: fm.InitReq): Promise<ListFacetsResponse> {
    return fm.fetchReq<ListFacetsRequest, ListFacetsResponse>(`/api/v1/eventlogs/${req["projectId"]}/facets?${fm.renderURLSearchParams(req, ["projectId"])}`, {...initReq, method: "GET"})
  }
  static GetFacet(req: GetFacetRequest, initReq?: fm.InitReq): Promise<GetFacetResponse> {
    return fm.fetchReq<GetFacetRequest, GetFacetResponse>(`/api/v1/eventlogs/${req["projectId"]}/facets/${req["facetId"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static QueryLog(req: LogQueryRequest, initReq?: fm.InitReq): Promise<LogQueryResponse> {
    return fm.fetchReq<LogQueryRequest, LogQueryResponse>(`/api/v1/eventlogs/${req["projectOwner"]}/${req["projectSlug"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static QueryLogMetrics(req: LogQueryRequest, initReq?: fm.InitReq): Promise<QueryLogMetricsResponse> {
    return fm.fetchReq<LogQueryRequest, QueryLogMetricsResponse>(`/api/v1/eventlogs/${req["projectOwner"]}/${req["projectSlug"]}/metrics`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}