import { useEffect } from 'react'
import { useFloating, FloatingPortal, shift } from '@floating-ui/react'

interface Props {
  referenceElement?: HTMLElement
  text: string
}

export function Tooltip({ referenceElement, text }: Props) {
  const { x, y, reference, floating, strategy } = useFloating({
    placement: 'bottom',
    middleware: [shift()],
  })

  useEffect(() => {
    if (referenceElement) reference(referenceElement)
  }, [reference, referenceElement])

  if (!referenceElement || !text) {
    return null
  }

  return (
    <FloatingPortal>
      <div
        ref={floating}
        className="bg-black/70 backdrop-opacity-60 text-white py-1 px-2 rounded-md pointer-events-none z-tooltip"
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        {text}
      </div>
    </FloatingPortal>
  )
}
