import { forwardRef, useEffect, useMemo, useState } from 'react'
import { EChartsHandle, EChartsOption, ReactEChartsBase } from './EchartsBase'
import { useAsyncComputeSeries } from 'lib/metrics/series-async'
import { ChartProps } from './Chart'
import { ChartConfigDirection, ChartConfigSortBy } from 'gen/service/web'
import { defaults, omitBy, isNull } from 'lodash'
import { defaultConfig } from './options/BarGaugeControls'
import { valueFormatter } from 'lib/metrics/formatter'
import { ChartConfigValueFormatter } from 'gen/service/web'
import { ChartConfig } from 'gen/service/web'

const compareOption: Intl.CollatorOptions = {
  numeric: true
}

const BarGaugeChart = forwardRef<EChartsHandle, ChartProps>((props: ChartProps, ref) => {
  const { title, data, minHeight, loading, style, chartType, onInitChart } = props
  const config: ChartConfig = defaults(omitBy(props.config, isNull), { barGauge: defaultConfig })
  const computeSeriesAsync = useAsyncComputeSeries()
  const [series, setSeries] = useState<any[]>([])
  const [legend, setLegend] = useState<string[]>()
  const [xAxis, setXAxis] = useState<any>()
  const [yAxis, setYAxis] = useState<any>()

  useEffect(() => {
    if (data?.results && chartType) {
      computeSeriesAsync(data?.results, chartType, config?.barGauge?.calculation).then((res) => {
        const { series: seriesData, legend } = res
        const tmpData = seriesData.map((s) => {
          const d = s.data && s.data[0]
          return { name: s.name, value: d && d[1]! }
        })
        const sort = config?.barGauge?.sort
        switch (sort?.sortBy) {
          case ChartConfigSortBy.ByName:
            tmpData.sort((a, b) =>
              sort.orderDesc
                ? b.name!.localeCompare(a.name!, undefined, compareOption)
                : a.name!.localeCompare(b.name!, undefined, compareOption)
            )
            break
          case ChartConfigSortBy.ByValue:
            tmpData.sort((a, b) => (sort.orderDesc ? b.value - a.value : a.value - b.value))
            break
        }

        const series: { type: 'bar'; data: number[]; label: any }[] = [
          {
            type: 'bar',
            data: tmpData.map((d) => d.value),
            label: {
              show: config.valueConfig ? config.valueConfig.showValueLabel : false,
              position: config?.barGauge?.direction == ChartConfigDirection.VERTICAL ? 'top' : 'right',
              formatter: ({ value }) => {
                return valueFormatter(config?.valueConfig)(value)
              }
            }
          }
        ]

        const seriesAxis = {
          type: 'category',
          data: tmpData.map((s) => s.name),
          axisLabel: config?.barGauge?.direction == ChartConfigDirection.VERTICAL ? { interval: 0, rotate: 30 } : {}
        }
        const valueAxis = {
          type: 'value',
          axisLabel:
            // show dates on value-axis label is weird
            config.valueConfig?.valueFormatter == ChartConfigValueFormatter.DateFormatter
              ? undefined
              : {
                  formatter: (v) => valueFormatter(config.valueConfig)(v)
                }
        }

        let xAxis, yAxis
        switch (config?.barGauge?.direction) {
          case ChartConfigDirection.VERTICAL:
            xAxis = seriesAxis
            yAxis = valueAxis
            break
          case ChartConfigDirection.HORIZONTAL:
          default:
            xAxis = valueAxis
            yAxis = seriesAxis
        }
        setSeries(series)
        setLegend(legend)
        setXAxis(xAxis)
        setYAxis(yAxis)
      })
    }
  }, [data?.results, chartType, config?.barGauge?.calculation])

  const dataZoom = useMemo(() => {
    if (config?.barGauge?.direction == ChartConfigDirection.HORIZONTAL) {
      return [
        {
          show: series[0]?.data.length > 15,
          type: 'slider',
          yAxisIndex: 0,
          zoomLock: true,
          width: 8,
          right: 10,
          top: 5,
          bottom: 30,
          minValueSpan: 5,
          maxValueSpan: 15,
          orient: 'vertical',
          handleSize: 0,
          showDetail: false,
          brushSelect: false,
          showDataShadow: false
          // start: zoom[0],
          // end: zoom[1],
        },
        {
          type: 'inside',
          id: 'insideY',
          yAxisIndex: 0,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
          // start: zoom[0],
          // end: zoom[1],
        }
      ]
    } else {
      return [
        {
          show: series[0]?.data.length > 25,
          type: 'slider',
          xAxisIndex: 0,
          zoomLock: true,
          height: 8,
          bottom: 5,
          maxValueSpan: 25,
          minValueSpan: 10,
          handleSize: '0',
          showDetail: false,
          orient: 'horizontal',
          brushSelect: false,
          showDataShadow: false
          // start: zoom[0],
          // end: zoom[1],
        },
        {
          type: 'inside',
          id: 'insideX',
          xAxisIndex: 0,
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnMouseWheel: true
          // start: zoom[0],
          // end: zoom[1],
        }
      ]
    }
  }, [config, series /*zoom*/])

  const options: EChartsOption = {
    title: {
      text: title
    },
    grid: {
      top: title ? 48 : 16,
      right: 40,
      bottom: 16,
      left: 16,
      containLabel: true
    },
    xAxis,
    legend: {
      data: legend,
      top: -10000,
      left: -10000
    },
    toolbox: {
      show: false
    },
    yAxis,
    dataZoom,
    animation: false,
    series,
    tooltip: {
      trigger: 'axis',
      confine: true,
      extraCssText: 'max-width: 50%; max-height: 50vh; overflow-y: auto;'
    }
  }

  /*function updateZoom(start, end) {
    setZoom([start, end])
  }*/

  return (
    <div className="h-full w-full">
      <ReactEChartsBase
        ref={ref}
        loading={loading}
        // group={group}  bar gauge chart should have independent scroll
        option={options}
        minHeight={minHeight}
        style={style}
        noLegend
        // onZoom={updateZoom}
        onInitChart={onInitChart}
      />
    </div>
  )
})
BarGaugeChart.displayName = 'BarGaugeChart'

export default BarGaugeChart
