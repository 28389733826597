import {
  GetFacetRequest,
  ListFacetsRequest,
  LogQueryRequest,
  LogQueryRequestSort,
  LogQueryResponse
} from 'gen/service/analytic'
import { getAccessToken } from './use-access-token'
import { DateTimeValue, toTimeLike } from '../time'
import { useProjectVersions } from './use-project-versions'
import useApi from './use-api'
import { calculateInterval } from '../interval'
import { getProjectUrl, useProject } from './use-project'
import { Permission } from 'gen/service/common'
import { useUrlQuery } from './use-url-query'
import useTimeRange from './use-timerange'
import { filter2str, flattenExpr, isTermFilter } from '../search'
import { useCallback, useMemo } from 'react'
import router from 'next/router'
import { parse } from '@sentio/search-string-parser'
import { SearchService } from '../../gen/service/analytic'
import mixpanel from 'mixpanel-browser'
import { useInfinite } from './use-infinite'
import { DefaultShowFacets } from 'components/logs/LogConstants'
import { useTemplateValues } from './use-template-values'
import { DashboardExtraTemplateVariable } from '@sentio/service/web'
import { Project } from '../../gen/service/common'
import { useLocalStorage } from './use-local-storage'

const PAGE_SIZE = 100

export function useFacetInfo(projectId: string | undefined, facetId?: string, filter = '', useQuery = true) {
  const [query, _] = useUrlQuery('query', '')
  const { startTime, endTime } = useTimeRange()
  const { currentVersion } = useProjectVersions(projectId)
  const q = useMemo(() => {
    if (useQuery && query) {
      // remove the facet filter from the query
      try {
        const filters = flattenExpr(parse(query))
        return filters
          .filter((f) => !(isTermFilter(f) && f.field == facetId))
          .map(filter2str)
          .join(' ')
      } catch (e) {
        return []
      }
    } else {
      return ''
    }
  }, [query, useQuery, facetId])
  const { data, error, loading, ready, mutate } = useApi(
    SearchService.GetFacet,
    projectId && facetId
      ? ({
          projectId,
          facetId,
          termFilter: filter,
          query: q,
          timeRange: {
            start: toTimeLike(startTime),
            end: toTimeLike(endTime)
          },
          version: currentVersion
        } as GetFacetRequest)
      : null
  )

  return {
    data,
    loading,
    ready,
    error,
    mutate
  }
}

export function useLogFacets(projectId: string | undefined) {
  const { currentVersion } = useProjectVersions(projectId)
  const req = {
    projectId,
    version: currentVersion
  } as ListFacetsRequest
  const { data, loading, mutate, ready, error } = useApi(SearchService.ListFacets, projectId ? req : null)
  return {
    facets: data?.facets,
    loading,
    mutate,
    ready,
    error
  }
}

// export function useLogFacetsByOwnerAndSlug(projectOwner: string | undefined, projectSlug: string | undefined) {
//   const { currentVersion } = useProjectVersions()
//   const req = {
//     projectOwner,
//     projectSlug,
//     version: currentVersion
//   } as ListFacetsRequest
//   const { data, loading, mutate, ready, error } = useApi(
//     SearchService.ListFacets,
//     projectOwner && projectSlug ? req : null
//   )
//   return {
//     facets: data?.facets,
//     loading,
//     mutate,
//     ready,
//     error
//   }
// }

function resolveTemplateValues(rawQuery?: string, templateValues?: Record<string, string>) {
  if (templateValues) {
    try {
      const queryList = rawQuery?.split(/\s+/)
      queryList?.forEach((queryStr, index) => {
        const [queryKey, queryValue] = queryStr.trim().split(':')
        if (queryValue?.startsWith('$')) {
          const templateKey = queryValue.slice(1)
          if (templateValues[templateKey]) {
            queryList[index] = `${queryKey}:${templateValues[templateKey]}`
          }
        }
      })
      return queryList?.join(' ')
    } catch {
      return rawQuery
    }
  }
  return rawQuery
}

export function makeLogQueryRequest(
  projectOwner: string | undefined,
  projectSlug: string | undefined,
  query: string,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  tz?: string,
  version?: number,
  limit?: number,
  sorts?: LogQueryRequestSort[],
  variables?: { [p: string]: string },
  after?: any
) {
  const resolvedQuery = resolveTemplateValues(query, variables)
  const req: LogQueryRequest = {
    projectOwner,
    projectSlug,
    query: resolvedQuery,
    sorts,
    limit: limit ?? PAGE_SIZE,
    timeRange: {
      start: toTimeLike(startTime),
      end: toTimeLike(endTime),
      timezone: tz
    },
    version,
    after
  }
  return req
}

export function useLogs(
  projectId: string | undefined,
  query: string,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  sorts: LogQueryRequestSort[] = [{ field: DefaultShowFacets.Timestamp, desc: true }],
  variables?: { [p: string]: DashboardExtraTemplateVariable },
  tz?: string,
  fromShare?: { shareId?: string; panelId?: string; project?: Project },
  ownerName?: string,
  slug?: string,
  latestVersion?: boolean
) {
  const [adminMode] = useLocalStorage('sentio_admin_mode', false)
  const { currentVersion } = useProjectVersions(projectId)
  const { project } = useProject([Permission.READ])
  const { templateValues } = useTemplateValues(variables)
  const req = makeLogQueryRequest(
    ownerName || project?.ownerName || fromShare?.project?.ownerName,
    slug || project?.slug || fromShare?.project?.slug,
    query,
    startTime,
    endTime,
    tz,
    latestVersion ? undefined : currentVersion,
    PAGE_SIZE,
    sorts,
    templateValues
  )

  const getKey = (pageIndex: number, previousPageData?: LogQueryResponse) => {
    if (!projectId) return null

    // reached the end
    if (previousPageData && (previousPageData.entries || []).length == 0) return null

    if (pageIndex === 0) return req

    return { ...req, after: previousPageData?.after }
  }

  const fetcher = async (req) => {
    if (req.after === null) {
      mixpanel.track('Search', { project: getProjectUrl(project), query })
    }
    if (req.projectOwner === undefined || req.projectSlug === undefined) {
      return { entries: [] }
    }
    const accessToken = await getAccessToken()
    const headers = {
      'content-type': 'application/json',
      authorization: accessToken == 'anonymous' ? undefined : `Bearer ${accessToken}`
    } as any
    if (fromShare?.shareId && fromShare?.shareId) {
      headers['share-dashboard'] = `${fromShare?.shareId}/${fromShare?.panelId}`
    }
    if (adminMode && accessToken != 'anonymous') {
      headers['x-admin-mode'] = true
    }
    return await SearchService.QueryLog(req, {
      pathPrefix: '',
      headers
    })
  }

  const { data, error, isLoadingMore, mutate, isReachingEnd, fetchNextPage, isRefreshing } = useInfinite(
    getKey,
    fetcher,
    (d) => d.entries || [],
    PAGE_SIZE
  )

  return {
    data,
    isLoadingMore,
    isReachingEnd,
    isRefreshing,
    fetchNextPage,
    error,
    mutate,
    rawReq: req
  }
}

export function useLogMetrics(
  projectId: string | undefined | null,
  query: string,
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  step: number,
  refreshInterval = 10000
) {
  const { project } = useProject([Permission.READ])

  const { data, loading, ready, error, mutate } = useApi(
    SearchService.QueryLogMetrics,
    projectId && {
      projectOwner: project?.ownerName,
      projectSlug: project?.slug,
      projectId,
      query,
      timeRange: {
        start: toTimeLike(startTime),
        end: toTimeLike(endTime),
        step: step || calculateInterval(startTime, endTime)
      }
    },
    false,
    {
      refreshInterval,
      dedupingInterval: refreshInterval,
      focusThrottleInterval: refreshInterval
    }
  )

  return {
    data,
    loading,
    error,
    ready,
    mutate
  }
}

export function useLogUrls() {
  const generateLogUrl = useCallback(function generateLogUrl(
    query: string,
    timeRange?: { from?; to? },
    labelSet?: Record<string, string>
  ) {
    const { owner, slug } = router.query
    const url = new URL(`/${owner}/${slug}/logs`, window.location.origin)
    let q = query
    if (timeRange?.from) {
      url.searchParams.set('from', timeRange.from)
    }
    if (timeRange?.to) {
      url.searchParams.set('to', timeRange.to)
    }
    if (labelSet) {
      q =
        q +
        ' ' +
        Object.entries(labelSet)
          .map(([key, value]) => {
            return `${key}:"${value}"`
          })
          .join(' ')
    }
    url.searchParams.set('query', q)
    return url.toString()
  }, [])

  return {
    generateLogUrl
  }
}
