import { createContext } from 'react'
import { Project } from '../../gen/service/common'

export interface IQueryRangeContext {
  isShare?: boolean
  shareId?: string
  shareProject?: Project
}

export const QueryRangeContext = createContext<IQueryRangeContext>(null as unknown as IQueryRangeContext)
