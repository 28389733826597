import { defaults } from 'lodash'
import { ChartConfigYAxisConfig } from 'gen/service/web'
import { DisclosurePanel } from 'components/common/panel/DisclosurePanel'
import Button from 'components/common/buttons/NewButton'
interface Props {
  yAxis?: ChartConfigYAxisConfig
  setYAxis: (yAxis: ChartConfigYAxisConfig) => void
  defaultOpen?: boolean
}

const initialConfig = {
  yAxis: {
    min: '',
    max: '',
    scale: true,
    stack: ''
  }
}

export default function YaxisControls({ yAxis, setYAxis, defaultOpen }: Props) {
  yAxis = defaults(yAxis || {}, initialConfig.yAxis)
  const onChangeInput = (field) => (event) => {
    const { value } = event.target
    setYAxis({ ...yAxis, [field]: value || undefined, scale: field == 'min' && value > 0 ? true : yAxis?.scale })
  }
  const onToggleZero = (event) => {
    const { checked } = event.target
    setYAxis({ ...yAxis, scale: !checked, min: checked ? '' : yAxis?.min })
  }
  const onClickResetYAxis = () => {
    setYAxis(initialConfig.yAxis)
  }

  const minMaxLabelCls =
    'inline-flex items-center border border-r-0 sm:text-icontent border-gray-300  bg-gray-50 px-2 rounded-l-md'
  const minMaxInputCls = 'border focus:border-primary-500 rounded-r-md sm:text-icontent border-gray-300 '

  const onToggleStack = (event) => {
    const { checked } = event.target
    setYAxis({ ...yAxis, stacked: checked ? 'samesign' : '' })
  }
  return (
    <DisclosurePanel
      title="Y-Axis Controls"
      defaultOpen={defaultOpen}
      containerClassName="w-full rounded bg-white dark:bg-sentio-gray-100 border border-border-color"
    >
      <div className="text-icontent flex flex-wrap gap-[10px]">
        <label className="inline-flex h-8">
          <span className={minMaxLabelCls}>Min</span>
          <input
            type="text"
            className={minMaxInputCls}
            style={{ width: '10em' }}
            value={yAxis.min}
            placeholder="Auto"
            onChange={onChangeInput('min')}
          />
        </label>
        <label className="inline-flex h-8">
          <span className={minMaxLabelCls}>Max</span>
          <input
            type="text"
            className={minMaxInputCls}
            style={{ width: '10em' }}
            value={yAxis.max}
            placeholder="Auto"
            onChange={onChangeInput('max')}
          />
        </label>

        {/*  <div className="flex rounded-md shadow-sm h-8">
          <span className="inline-flex items-center rounded-l-md border border-gray-300  bg-gray-50 px-3 text-gray-500 text-icontent whitespace-nowrap">
            Stack Series
          </span>
          <select
            value={yAxis.stacked}
            className="inline-flex items-center py-0 pl-4 pr-7 border border-l-0 rounded-r-md  border-gray-300   text-gray-500 text-icontent"
            onChange={(e) => onChangeInput('stacked')(e)}
          >
            {stackStrategies.map((d) => {
              return (
                <option key={d.value} value={d.value}>
                  {d.label}
                </option>
              )
            })}
          </select>
        </div>*/}

        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="border-border-color mr-1 rounded"
            checked={!!yAxis?.stacked}
            onChange={onToggleStack}
          />
          Stack series
        </label>

        <label className="inline-flex items-center">
          <input
            type="checkbox"
            className="border-border-color mr-1 rounded"
            checked={!yAxis.scale}
            onChange={onToggleZero}
          />
          Always show zero
        </label>

        <Button role="link" onClick={onClickResetYAxis}>
          Reset
        </Button>
      </div>
    </DisclosurePanel>
  )
}

const stackStrategies = [
  { value: '', label: 'None' },
  { value: 'samesign', label: 'Same sign' },
  { value: 'all', label: 'All' },
  { value: 'positive', label: 'Positive' },
  { value: 'negative', label: 'Negative' }
]
