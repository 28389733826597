import useApi from './use-api'
import { MetricsQueryResponse, MetricsQueryResponseSample, ObservabilityService } from 'gen/service/observability'
import { dateTimeToString, DateTimeValue } from '../time'
import { Formula, Permission, Query } from 'gen/service/common'
import { calculateInterval } from '../interval'
import { ChartChartType } from 'gen/service/web'
import { produce } from 'immer'
import { useProject } from './use-project'
import { useProjectVersions } from './use-project-versions'
import { QueryRangeRequest } from '../../gen/service/observability'
import { useMemo, useState } from 'react'
import { StatusCodes } from 'http-status-codes'
import { Project } from '../../gen/service/common'

export const ChartTypeLimits = {
  [ChartChartType.TABLE]: 200,
  [ChartChartType.BAR_GAUGE]: 200
}
export const ChartTypeLimitsMax = {
  [ChartChartType.TABLE]: 1000,
  [ChartChartType.BAR_GAUGE]: 1000
}

export function resolveVariables(queries: Query[], variablesValues: { [p: string]: string }) {
  return produce(queries, (draft) => {
    draft.forEach((query) => {
      const labelSelector = query.labelSelector || {}
      Object.keys(labelSelector).forEach((key) => {
        const value = labelSelector[key]
        let field = key
        if (key == 'contract') {
          field = 'contract_name'
          delete labelSelector[key]
        } else if (key == 'address') {
          field = 'contract_address'
          delete labelSelector[key]
        }
        if (value?.startsWith('$')) {
          const variable = value.substring(1)
          const val = variablesValues[variable]
          if (val == null || val == '*') {
            delete labelSelector[field]
          } else {
            labelSelector[field] = val
          }
        }
      })
    })
  })
}

export function makeMetricsPayload(
  projectOwner: string | undefined,
  projectSlug: string | undefined,
  chart: { queries?: Query[]; formulas?: Formula[] },
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  tz?: string,
  variablesValues: { [p: string]: string } = {},
  version?: number,
  seriesLimit = 20
) {
  return {
    projectOwner,
    projectSlug,
    queries: resolveVariables(chart.queries || [], variablesValues),
    formulas: chart.formulas,
    timeRange: {
      start: dateTimeToString(startTime),
      end: dateTimeToString(endTime),
      step: calculateInterval(startTime, endTime),
      timezone: tz
    },
    samplesLimit: seriesLimit,
    version
  } as QueryRangeRequest
}

export function useQueryRange(
  projectId: string | undefined | null,
  queries: Query[],
  formulas: Formula[],
  startTime: DateTimeValue,
  endTime: DateTimeValue,
  tz: string,
  variableValues: { [p: string]: string } = {},
  refreshInterval = 10000,
  seriesLimit = 20,
  fromShare?: { shareId?: string; panelId?: string; project?: Project }
) {
  const { owner, slug } = useProject([Permission.READ])
  const { currentVersion } = useProjectVersions()

  const payload = makeMetricsPayload(
    fromShare?.project?.ownerName ?? (owner as string),
    fromShare?.project?.slug ?? (slug as string),
    { queries, formulas },
    startTime,
    endTime,
    tz,
    variableValues,
    currentVersion,
    seriesLimit
  )

  const isReady = projectId && queries && queries.some((q) => !!q.query)
  const [error, setError] = useState<any>()

  const options = useMemo(() => {
    return {
      refreshInterval,
      dedupingInterval: refreshInterval,
      focusThrottleInterval: refreshInterval,
      compare: compareFn,
      onSuccess: () => {
        setError(undefined)
      },
      onError: (error: any) => {
        // add more error handling here
        setError(error.status === StatusCodes.NOT_FOUND ? error.body : undefined)
      },
      fromShare
    }
  }, [refreshInterval])

  const { data, loading, ready, mutate } = useApi(
    ObservabilityService.QueryRange,
    isReady ? payload : null,
    false,
    options
  )

  return {
    data,
    loading,
    ready,
    error,
    mutate,
    payload
  }
}

function compareSample(pSample: MetricsQueryResponseSample, nSample: MetricsQueryResponseSample) {
  // quick and dirty compare by checking first and last sample
  if (pSample?.values?.length !== nSample?.values?.length) {
    return false
  }
  const pFirst = pSample.values && pSample.values[0]
  const pLast = pSample.values && pSample.values[pSample.values.length - 1]

  const nFirst = nSample.values && nSample.values[0]
  const nLast = nSample.values && nSample.values[nSample.values.length - 1]

  return (
    nFirst?.value === pFirst?.value &&
    nLast?.value === pLast?.value &&
    nFirst?.timestamp === pFirst?.timestamp &&
    nLast?.timestamp === pLast?.timestamp
  )
}

function compareFn(prev: MetricsQueryResponse, next: MetricsQueryResponse): boolean {
  if (prev == null && next == null) {
    return true
  } else if (prev == null && next != null) {
    return next?.results == null
  }

  const nResults = next?.results ?? []
  const pResults = prev?.results ?? []
  if (pResults.length !== nResults?.length) {
    return false
  }

  try {
    for (const i in pResults) {
      const p = pResults[i]
      const n = nResults[i]
      if (p?.error != n?.error) {
        return false
      }
      if (p?.matrix?.samples?.length != n?.matrix?.samples?.length) {
        return false
      }

      for (const j in p?.matrix?.samples || []) {
        const pSamples = p?.matrix?.samples || []
        const pSample = pSamples[j]
        const nSamples = n?.matrix?.samples || []
        const nSample = nSamples[j]
        if (!compareSample(pSample, nSample)) {
          return false
        }
      }
    }
  } catch {
    return true
  }

  return true
}
