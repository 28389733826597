import { computeSeries } from 'lib/metrics/series'
import { valueFormatter } from 'lib/metrics/formatter'
import { ChartConfig, ChartConfigCalculation } from 'gen/service/web'
import { ChartConfigMappingRule, ChartChartType, ChartConfigColorTheme } from 'gen/service/web'
import { mean, sum, min, max, last, first } from 'lodash'
import { upgradeTheme, ThemeType, getThemeColor } from 'lib/colors/query-value-theme'
import { defaultConfig } from 'components/charts/options/QueryValueControls'
import { ColorLuminance } from 'lib/color'
import { MetricsQueryResponse } from '@sentio/service/observability'

export const eventName = 'QueryValueChartComputeSeries'
export const parser = ({
  data,
  chartType,
  config,
  isDarkMode
}: {
  data: MetricsQueryResponse | undefined
  chartType: ChartChartType | undefined
  config: ChartConfig
  isDarkMode: boolean
}) => {
  const { series } = computeSeries(data?.results || [], chartType, config?.queryValueConfig?.calculation)

  let value: number | undefined = 0
  if (series[0]) {
    const data = series.map((s) => {
      const d = s.data && s.data[0]
      return d && d[1]!
    })
    switch (config.queryValueConfig?.seriesCalculation) {
      case ChartConfigCalculation.MEAN:
        value = mean(data)
        break
      case ChartConfigCalculation.TOTAL:
        value = sum(data)
        break
      case ChartConfigCalculation.MIN:
        value = min(data)
        break
      case ChartConfigCalculation.MAX:
        value = max(data)
        break
      case ChartConfigCalculation.LAST:
        value = last(data)
        break
      case ChartConfigCalculation.FIRST:
      default: {
        value = first(data)
      }
    }
  }

  const targetColorTheme = config?.queryValueConfig?.colorTheme || defaultConfig.colorTheme
  let { themeType } = targetColorTheme
  if (!themeType) {
    themeType = upgradeTheme(targetColorTheme as ChartConfigColorTheme)
  }

  const themeColor = getThemeColor(themeType, isDarkMode)
  let { textColor, backgroundColor, areaColor, lineColor } = themeColor.value

  const ruleCallback = (rule: ChartConfigMappingRule) => {
    if (
      !rule.colorTheme ||
      rule.colorTheme.textColor === 'inherit' ||
      rule.colorTheme.themeType === ThemeType.Unchanged
    )
      return
    let { themeType } = rule.colorTheme
    if (!themeType) {
      themeType = upgradeTheme(rule.colorTheme)
    }
    ;({ textColor, backgroundColor, areaColor, lineColor } = themeColor.value)
  }
  const valueText = valueFormatter(config?.valueConfig)(value, ruleCallback)

  if (!config?.queryValueConfig?.showBackgroundChart) {
    return {
      series: [],
      valueText,
      textColor,
      backgroundColor
    }
  }

  const bgSeries = computeSeries(data?.results || [], ChartChartType.LINE)?.series || []
  bgSeries.forEach((s) => {
    switch (backgroundColor) {
      case 'transparent':
        s.areaStyle = {
          color: ColorLuminance(textColor, 0.1)
        }
        break
      default:
        s.areaStyle = {
          color: areaColor || ColorLuminance(backgroundColor, -0.1)
        }
    }
    switch (textColor) {
      case '#131D2E':
        s.lineStyle = {
          color: '#D0D7DE'
        }
        break
      case '#ffffff':
        s.lineStyle = {
          color: ColorLuminance(backgroundColor, -0.2)
        }
        break
      default:
        s.lineStyle = {
          color: lineColor || textColor
        }
    }
    s.stack = 'total'
  })

  return {
    series: bgSeries,
    valueText,
    textColor,
    backgroundColor
  }
}
