import { Field, FieldExp, NodeExp, Operator, Range, Inclusive } from '@sentio/search-string-parser'
export * from './filter'
export type { NodeExp, Range, FieldExp, Field, Inclusive }
export { Operator } from '@sentio/search-string-parser'
export function ast2str(node: NodeExp | FieldExp): string {
  if (isNodeExp(node)) {
    const { left, operator, right, parenthesized, field, prefix } = node
    let ret = `${prefix || ''}${field ? `${field}:` : ''}` + `${parenthesized ? '(' : ''}` + ast2str(left)
    if (right) {
      ret += (operator && operator !== Operator.Implicit ? ` ${operator} ` : ' ') + (right ? ast2str(right) : '')
    }
    ret += `${parenthesized ? ')' : ''}`
    return ret
  } else if (isField(node)) {
    const term = node.quoted ? `"${node.term}"` : node.regex ? `/${node.term}/` : node.term
    return `${node.field ? `${node.field}:` : ''}${term}`
  } else if (isRange(node)) {
    const { field, term_min: min, term_max: max, inclusive } = node
    let range = `${min} TO ${max}`
    switch (inclusive) {
      case Inclusive.left:
        range = `[${range}}`
        break
      case Inclusive.right:
        range = `{${range}]`
        break
      case Inclusive.none:
        range = `{${range}}`
        break
      default:
        range = `[${range}]`
    }
    return `${field}${range}`
  } else {
    throw new Error('Unknown node type')
  }
}

export function isNodeExp(node: NodeExp | FieldExp): node is NodeExp {
  return (node as NodeExp).left !== undefined || (node as NodeExp).operator !== undefined
}

export function isField(node: NodeExp | FieldExp): node is Field {
  return (node as Field).term !== undefined
}

export function isRange(node: NodeExp | FieldExp): node is Range {
  return (node as Range).term_min !== undefined
}

export function traverse(node: NodeExp | FieldExp, fn: (node: NodeExp | FieldExp) => void) {
  fn(node)
  if (isNodeExp(node)) {
    traverse(node.left, fn)
    if (node.right) {
      traverse(node.right, fn)
    }
  }
}
