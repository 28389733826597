import { DashboardExtraTemplateVariable } from 'gen/service/web'
import { useUrlQueries } from './use-url-query'
import { useCallback, useMemo } from 'react'
import { each, cloneDeep } from 'lodash'

const EmptyObject = {}

export function useTemplateValues(variables?: { [p: string]: DashboardExtraTemplateVariable }) {
  const [_templateValues, setTemplateValues] = useUrlQueries('tpl_var_', EmptyObject)

  const templateValues = useMemo(() => {
    const defaultValues = cloneDeep(_templateValues)
    each(variables, (variable, variableName) => {
      if (defaultValues[variableName] == null || defaultValues[variableName] == '') {
        defaultValues[variableName] = variable.defaultValue || '*'
      }
    })
    return defaultValues
  }, [variables, _templateValues])

  const resetTemplateValues = useCallback(
    (variables) => {
      const newValues = {}
      for (const key in variables) {
        newValues[key] = _templateValues[key]
      }
      setTemplateValues(newValues)
    },
    [_templateValues, setTemplateValues]
  )

  return {
    templateValues: templateValues,
    setTemplateValues,
    resetTemplateValues
  }
}
