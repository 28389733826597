import { Field, FieldExp, Inclusive, NodeExp, Operator } from '@sentio/search-string-parser'
import { ast2str, isField, isNodeExp, isRange } from './index'

export type TermFilter = {
  field: string
  terms: string[]
  not?: boolean
}

export type RangeFilter = {
  field: string
  min: any
  max: any
  inclusive?: Inclusive
  not?: boolean
}

export type Input = {
  text: string
}

export type Filter = TermFilter | RangeFilter | Input | Operator

export function isInput(node: Filter): node is Input {
  return (node as Input)?.text !== undefined
}

export function isOperator(node: Filter): node is Operator {
  return node === Operator.And || node === Operator.Or || node === Operator.Implicit
}

export function isTermFilter(node: Filter): node is TermFilter {
  return (node as TermFilter)?.terms !== undefined
}

export function isRangeFilter(node: Filter): node is RangeFilter {
  const f = node as RangeFilter
  return f?.min !== undefined && f?.max !== undefined
}

export function filter2str(node: Filter) {
  if (isTermFilter(node)) {
    const terms = node.terms.length > 1 ? `(${node.terms.map(quoteIfNeed).join(' OR ')})` : quoteIfNeed(node.terms[0])
    return `${node.not ? '-' : ''}${node.field}:${terms}`
  } else if (isRangeFilter(node)) {
    let range = `${node.min} TO ${node.max}`
    switch (node.inclusive) {
      case Inclusive.left:
        range = `[${range}}`
        break
      case Inclusive.right:
        range = `{${range}]`
        break
      case Inclusive.none:
        range = `{${range}}`
        break
      default:
        range = `[${range}]`
    }
    return `${node.field}:${range}`
  } else if (isInput(node)) {
    return node.text
  } else {
    return node as string
  }
}

export function flattenExpr(expr: NodeExp): Filter[] {
  const result = [] as Filter[]

  function extractTerm(field: Field) {
    return field.quoted ? `"${field.term}"` : field.regex ? `/${field.term}/` : field.term
  }

  function extractField(field: Field) {
    if (field.field == '' || field.field == '<implicit>') {
      result.push({ text: extractTerm(field) })
      return
    }
    result.push({
      field: field.field!,
      terms: [field.term],
      not: field.prefix == '-',
    })
  }

  function extractFieldTerms(node: NodeExp | FieldExp, terms: string[]) {
    if (isNodeExp(node)) {
      extractFieldTerms(node.left, terms)
      if (node.operator != Operator.Or) {
        return false
      }
      if (node.right) {
        extractFieldTerms(node.right, terms)
      }
    } else if (isField(node)) {
      terms.push(extractTerm(node))
    }
    return true
  }

  function extract(node: NodeExp | FieldExp) {
    if (isRange(node) && node.field) {
      result.push({
        field: node.field,
        min: node.term_min,
        max: node.term_max,
        inclusive: node.inclusive,
        not: node.prefix == '-',
      })
    } else if (isField(node)) {
      extractField(node)
    } else if (isNodeExp(node)) {
      if (node.field) {
        // key:(foo OR bar)
        const terms = []
        if (extractFieldTerms(node, terms)) {
          result.push({
            field: node.field!,
            terms,
            not: node.prefix == '-',
          })
        } else {
          result.push({ text: ast2str(node) })
        }
      } else {
        // logical expression
        if (node.start) {
          result.push(node.start as Operator)
        }
        node.left && extract(node.left)
        if (node.operator && node.operator !== Operator.Implicit) {
          result.push(node.operator)
        }
        node.right && extract(node.right)
      }
    }
  }

  extract(expr)

  //merge neighbour input filters
  return result.reduce((acc, cur) => {
    if (acc.length == 0) {
      acc.push(cur)
    } else {
      const last = acc[acc.length - 1]
      if (isInput(last) && isInput(cur)) {
        last.text = last.text + ' ' + cur.text
      } else {
        acc.push(cur)
      }
    }
    return acc
  }, [] as Filter[])
}

const specialCharsRegex = /[+\-=& |><!(){}[\]"'*?:\\]/

export function quoteIfNeed(input: string): string {
  if (input.startsWith('"') && input.endsWith('"')) {
    return input
  }

  if (specialCharsRegex.test(input)) {
    return `"${input.replaceAll('"', `\\"`)}"`
  }
  return input
}
