import { forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import { EChartsHandle, EChartsOption, ReactEChartsBase } from './EchartsBase'
import { useAsyncComputeSeries } from 'lib/metrics/series-async'
import { ChartProps } from './Chart'
import { defaults, omitBy, isNull, isEqual } from 'lodash'
import { defaultConfig } from './options/PieChartControls'
import { valueFormatter } from 'lib/metrics/formatter'
import { ChartConfigPieConfigPieType } from 'gen/service/web'
import { ChartConfig } from 'gen/service/web'

const PieChart = forwardRef<EChartsHandle, ChartProps>((props: ChartProps, ref) => {
  const { title, data, minHeight, loading, style, chartType, onInitChart } = props
  const config: ChartConfig = defaults(omitBy(props.config, isNull), { pieConfig: defaultConfig })
  const _valueFormatter = useMemo(() => valueFormatter(config.valueConfig), [config.valueConfig])
  const computeSeriesAsync = useAsyncComputeSeries()
  const [options, setOptions] = useState<EChartsOption>({})
  const cacheRef = useRef<any>(null)

  useEffect(() => {
    if (isEqual(cacheRef.current, { data, chartType, config, ref, _valueFormatter })) {
      return
    }
    cacheRef.current = {
      data,
      chartType,
      config,
      ref,
      _valueFormatter
    }
    if (data?.results && chartType) {
      computeSeriesAsync(data?.results, chartType, config?.pieConfig?.calculation).then((res) => {
        const { series: seriesData } = res

        const d = [] as any[]
        seriesData.forEach((s) => {
          if (s.data.length > 0) {
            d.push({
              name: s.name,
              value: s.data[0][1]
            })
          }
        })

        const series = [
          {
            type: 'pie',
            radius: [config?.pieConfig?.pieType == ChartConfigPieConfigPieType.Donut ? '40%' : 0, '70%'],
            itemStyle: {
              borderColor: '#fff',
              borderWidth: 1
            },
            label: {
              alignTo: 'edge',
              formatter: ({ name, data, percent }) => {
                let ret = `${name}`
                if (config?.pieConfig?.showValue) {
                  ret += '\n' + _valueFormatter(data.value)
                }
                if (config?.pieConfig?.showPercent) {
                  ret += config.pieConfig.showValue ? ` • ${percent}%` : `\n${percent}%`
                }
                return ret
              },

              minMargin: 5,
              edgeDistance: 10,
              lineHeight: 16
            },
            labelLine: {
              length: 10,
              length2: 10,
              maxSurfaceAngle: 50
            },
            data: d,
            labelLayout: function (params) {
              const isLeft = ref && params.labelRect.x < ref['current'].clientWidth / 2
              const points = params.labelLinePoints
              points[2][0] = isLeft ? params.labelRect.x : params.labelRect.x + params.labelRect.width
              return {
                labelLinePoints: points
              }
            }
          }
        ]
        const options: EChartsOption = {
          title: {
            text: title
          },
          grid: {
            top: title ? 48 : 8,
            right: 40,
            bottom: 8,
            left: 8,
            containLabel: true
          },
          tooltip: {
            trigger: 'item',
            formatter: ({ name, data, percent }) => {
              let ret = `${name}`
              if (config?.pieConfig?.showValue) {
                ret += '<br/>' + _valueFormatter(data.value)
              }
              if (config?.pieConfig?.showPercent) {
                ret += config.pieConfig.showValue ? ` (${percent}%)` : `\n${percent}%`
              }
              return ret
            }
          },
          toolbox: {
            show: false
          },
          legend: {
            show: false
          },
          animation: false,
          series: series as any
        }
        setOptions(options)
      })
    }
  }, [data, chartType, config, ref, _valueFormatter])

  return (
    <div className="h-full w-full">
      <ReactEChartsBase
        ref={ref}
        loading={loading}
        option={options}
        minHeight={minHeight}
        style={style}
        noLegend
        onInitChart={onInitChart}
      />
    </div>
  )
})

PieChart.displayName = 'PieChart'

export default PieChart
