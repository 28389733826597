import classNames from '../../lib/classnames'
import { Menu, Transition } from '@headlessui/react'
import { MenuItem } from './SubMenu'
import { HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  items: IMenuItem[]
  itemLabelClassName?: string
  onSelectMenu?: OnSelectMenuItem
}

function MenuComponent({ items, itemLabelClassName, onSelectMenu, ...rest }: Props) {
  return (
    <Menu as="div" className="z-20 inline-block text-left" {...rest}>
      <Menu.Items
        className="dark:bg-sentio-gray-100 relative mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:ring-gray-100"
        static
      >
        <div className="py-1">
          {items.map((item) => (
            <Menu.Item key={item.key}>
              <MenuItem item={item} onSelect={onSelectMenu} key={item.key} labelClassName={itemLabelClassName} />
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  )
}

export default MenuComponent
