import {
  dateTimeToString,
  DateTimeValue,
  DEFAULT_FROM,
  DEFAULT_TO,
  DEFAULT_TZ,
  fromTimeLike,
  parseDateTime,
  toTimeLike
} from '../time'
import { useApiMutate } from 'lib/data/use-api'
import { ChartConfigTimeRangeOverride } from 'gen/service/web'
import { useCallback, useMemo } from 'react'
import { produce } from 'immer'
import { useUrlQueries } from './use-url-query'

interface ITimeRangeContext {
  startTime: DateTimeValue
  endTime: DateTimeValue
  tz: string
  setTimeRange: (start?: DateTimeValue, end?: DateTimeValue, tz?: string) => void
  refresh: () => void
}

export function useTimeRangeWithDefaultFromStorage(storageKey?: string) {
  const [defaultStart, defaultEnd, defaultTz] = useMemo(() => {
    let defaultStart: DateTimeValue
    let defaultEnd: DateTimeValue
    if (typeof window !== 'undefined' && storageKey) {
      const startFromStorage = localStorage.getItem(`${storageKey}.startTime`) || undefined
      const endFromStorage = localStorage.getItem(`${storageKey}.endTime`) || undefined
      defaultStart = parseDateTime(startFromStorage) || DEFAULT_FROM
      defaultEnd = parseDateTime(endFromStorage) || DEFAULT_TO
      const defaultTz = localStorage.getItem(`${storageKey}.tz`) || DEFAULT_TZ
      return [defaultStart, defaultEnd, defaultTz]
    }

    return [DEFAULT_FROM, DEFAULT_TO, DEFAULT_TZ]
  }, [storageKey])

  return useTimeRange(defaultStart, defaultEnd, defaultTz)
}

export default function useTimeRange(
  defaultStart: DateTimeValue = DEFAULT_FROM,
  defaultEnd: DateTimeValue = DEFAULT_TO,
  defaultTz: string = DEFAULT_TZ
): ITimeRangeContext {
  const initState = useMemo(() => {
    return {
      from: dateTimeToString(defaultStart),
      to: dateTimeToString(defaultEnd),
      tz: defaultTz
    }
  }, [defaultStart, defaultEnd])
  const [{ from, to, tz }, setValue] = useUrlQueries('', initState)
  useApiMutate()
  const refresh = () => {
    // mutateApi(ObservabilityService.QueryRange)
    if (typeof window == 'object') {
      window.dispatchEvent(new Event('refresh_all'))
    }
  }

  const { startTime, endTime, timeZone } = useMemo(() => {
    return {
      startTime: parseDateTime(from) || defaultStart,
      endTime: parseDateTime(to) || defaultEnd,
      timeZone: tz || defaultTz
    }
  }, [from, to, tz, defaultEnd, defaultStart, defaultTz])

  const setTimeRange = useCallback(
    (startTime?: DateTimeValue, endTime?: DateTimeValue, tz?: string) => {
      setValue({ from: dateTimeToString(startTime), to: dateTimeToString(endTime), tz: tz || timeZone })
    },
    [setValue]
  )

  return {
    startTime: startTime || defaultStart,
    endTime: endTime || defaultEnd,
    tz: timeZone || defaultTz,
    refresh,
    setTimeRange
  }
}

export function useTimeRangeWithOverrideConfig(
  config?: ChartConfigTimeRangeOverride,
  onConfigChange?: (config: ChartConfigTimeRangeOverride) => void,
  storageKey?: string
) {
  const {
    startTime: globalStartTime,
    endTime: globalEndTime,
    setTimeRange: setGlobalTimeRange,
    refresh,
    tz
  } = useTimeRangeWithDefaultFromStorage(storageKey)

  const { startTime, endTime } = useMemo(() => {
    if (config?.enabled) {
      return {
        startTime: fromTimeLike(config?.timeRange?.start) || globalStartTime,
        endTime: fromTimeLike(config?.timeRange?.end) || globalStartTime
      }
    } else {
      return {
        startTime: globalStartTime,
        endTime: globalEndTime
      }
    }
  }, [globalStartTime, globalEndTime, config])

  const setTimeRange = useCallback(
    (start?: DateTimeValue, end?: DateTimeValue) => {
      if (config?.enabled && onConfigChange) {
        onConfigChange(
          produce(config, (draft) => {
            draft.timeRange = {
              start: toTimeLike(start),
              end: toTimeLike(end)
            }
          })
        )
      } else {
        setGlobalTimeRange(start, end)
      }
    },
    [config, onConfigChange, setGlobalTimeRange]
  )

  return {
    startTime: startTime,
    endTime: endTime,
    refresh,
    setTimeRange,
    tz
  }
}
